import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    charts: [],

}

const chartSlice = createSlice({
    name: "chart",
    initialState,
    reducers: {
        setCharts: (state, action) => {
            state.charts  = action.payload

        }, 
    }
})

export const { setCharts } = chartSlice.actions;
export const selectCharts = (state) => state.chart.charts;
export default chartSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    songs: [],

}

const songSlice = createSlice({
    name: "song",
    initialState,
    reducers: {
        setSongs: (state, action) => {
            state.songs  = action.payload

        }, 
    }
})

export const { setSongs } = songSlice.actions;
export const selectSongs = (state) => state.song.songs;
export default songSlice.reducer;
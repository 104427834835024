import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    musiks: [],

}

const musikSlice = createSlice({
    name: "musik",
    initialState,
    reducers: {
        setMusiks: (state, action) => {
            state.musiks  = action.payload

        }, 
    }
})

export const { setMusiks } = musikSlice.actions;
export const selectMusiks = (state) => state.musik.musiks;
export default musikSlice.reducer;
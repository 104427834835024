import {React, } from 'react'
//import { Helmet } from 'react-helmet'
// import db from '../../firebase'
// import {useDispatch} from "react-redux"
// import {setArtists} from "../../features/artist/artistSlice"
//import Herosection from '../Herosection'
//import Navbar from '../navbars/Navbar2'
//import Nav2 from '../Navs/Nav2'
import Nav3 from '../Navs/SideView/Nav3'

import Content from '../snowfm/Content2'
import Chart from './chart'
import RecentlyAdded from '../audio/Songs/RecentlyAdded'
//import NonstopPage from './NonstopPage'
//import Artists2 from './Artists2'
import GoogleAd2 from '../../components/GoogleAd2';
//import Slider from '../../components/audio/Songs/Slider'
import GoogleAd from '../GoogleAd'
import Newhits from './Newhits'
import RadioPlayer from '../snowfm/Radio';
import RecentAddedHitsPage2 from './RecentlyAddedHitsPage2'
import RecentSlides from '../audio/Songs/Silder/RecentSilder'
import ProgramSchedule from './ProgramSchedule'

function Home() {

     return (
        <div style={{background:'#030617'}}>
  
        <Nav3 />
        <Content />
        <RecentSlides/>
        <ProgramSchedule/>

        {/* <Chart /> */}
        {/* <GoogleAd2/> */}
           {/* <RadioPlayer /> */}
         <RecentAddedHitsPage2 />        
           <GoogleAd/> 
  
           {/* <Newhits />  */}



        </div>
    )
}

export default Home

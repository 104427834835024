import {React} from 'react'
import styled from 'styled-components'

function photos() {

    return (
        <>  
                        <Wrap>
                        <img src='/images/edrine.jpg' alt=''/>

                        </Wrap>
                        <Wrap>
                        <img src='/images/heli.jpg' alt=''/>

                        </Wrap>
                        <Wrap>
                        <img src='/images/lola-loice.jpg' alt=''/>
                        </Wrap>
                        <Wrap>
                        <img src='/images/acher.jpg' alt=''/>
                        </Wrap>
                        <Wrap>
                        <img src='/images/bnelly.jpg' alt=''/>
                        </Wrap>  <Wrap>
                        <img src='/images/chance.jpg' alt=''/>
                        </Wrap>  <Wrap>
                        <img src='/images/gen.jpg' alt=''/>
                        </Wrap>  <Wrap>
                        <img src='/images/ghope.jpg' alt=''/>
                        </Wrap>  <Wrap>
                        <img src='/images/kalz.jpg' alt=''/>
                        </Wrap>  <Wrap>
                        <img src='/images/kdos.jpg' alt=''/>
                        </Wrap>  <Wrap>
                        <img src='/images/ray.jpg' alt=''/>
                        </Wrap>  <Wrap>
                        <img src='/images/seezz.jpg' alt=''/>
                        </Wrap>  <Wrap>
                        <img src='/images/shilla.jpg' alt=''/>
                        </Wrap>  <Wrap>
                        <img src='/images/tip.jpg' alt=''/>
                        </Wrap>  <Wrap>
                        <img src='/images/trino.jpg' alt=''/>
                        </Wrap> <Wrap>
                        <img src='/images/van.jpg' alt=''/>
                        </Wrap> <Wrap>
                        <img src='/images/vicky.jpg' alt=''/>
                        </Wrap> <Wrap>
                        <img src='/images/vox.jpg' alt=''/>
                        </Wrap> <Wrap>
                        <img src='/images/warsh.jpg' alt=''/>
                        </Wrap>
                        <Wrap>
                        <img src='/images/Wasafi.jpg' alt=''/>
                        </Wrap>


  
        </>
    )
}

export default photos
const Wrap =styled.div`
 position: flex;
    margin: 50px 0 45px;
`

// const Img =styled.img`
//  position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     display: block;
//     width: 10%;
//     height: 10%;
//     max-height: 100%;
//     object-fit: cover;
//     transition: all 0.2s linear;
// `

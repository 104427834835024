import React from 'react'
import styled from 'styled-components'
const Ads = () => {
    return (
        <Container>
            <Title>ADVERTISE WITH US</Title>
            <Ptag>We are the LIONS of Digital Advertisement in Western Ug, Let your business reach to thousands across the internet through our websites, Apps, Social Media, Online TV and Snow FM. Talk to us via konzovibez@gmail.com, +256789618464(Only Whatsapp/SMS)</Ptag>

        </Container>
    )
}

export default Ads



const Container = styled.div`
background-color:#030617 ;
background-repeat: repeat;

//background: url('./images/ywd logo.PNG') center center/cover no-repeat;
height: 200px;
width: 100%;
/* margin-left: 50px;
margin-right: 50px; */
display: flex;
justify-content: center;
flex-direction:column;
align-items:center;
cursor: pointer;
border-radius: 4px;
object-fit: contain;
position: relative;
color: #fff;
& :hover {

    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {

}
`;
const Title = styled.h4`
color: #fff;
font-size:20px;
`
const Ptag = styled.p`
color: #fff;
font-size:15px;
`
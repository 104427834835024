import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'

const FooterContainer =styled.div`
    background-color: #202938;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom:10px;
    height: 30px;
    text-decoration: none;
`
const Footer2small = styled.small`
     text-align: center;
    display: flex;
    color: #fff;
    font-size:  0.81rem;
    justify-content:center;
    align-items: center;
@media screen and (max-width:768px){
  display: flex;
  font-size: 0.70rem;
 }

`
const NavItem = styled.li`
display: flex;
//position: relative;
//justify-content:end;
//align-items: center;
& hover {
}

@media  screen and (max-width: 768px){
    width: 100%;
& hover {
    border: none;
}
}
`;
const NavLinks = styled(Link)`

  color: white;

    font-size: 0.80rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.21rem 0.89rem;


@media screen and (max-width: 960px){
  padding: 0.1rem 0.1rem;
    text-align: center;
    display: flex;
    font-size: 0.61rem;
}
`;

const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;
`;


const Footer2 = () => {
    return (

        

        <FooterContainer>
                <Footer2small>
                Crafted by  {" "} //<a style={{color:"orange"}} href='https://facebook.com/kihembo.wilbert'> <strong>KIHEMBO WILBERT </strong> </a> 
         
            </Footer2small>
            <Footer2Links>
<NavMenu>
<NavItem>
                             <NavLinks to='/about'>About Us</NavLinks>
                         </NavItem>
                 <NavItem>
                             <NavLinks to='/contacts'>Upload Music</NavLinks>
                         </NavItem>
                         <NavItem>
                             <NavLinks to='/privacy-policy'>Privacy</NavLinks>                         </NavItem>
                             <NavItem>
                             <NavLinks to='/terms-and-conditions'>Terms </NavLinks>
                        </NavItem>
                        <NavItem>
                             <NavLinks to='/advertise'>Advertise</NavLinks>
                        </NavItem>
                         </NavMenu>
                         </Footer2Links>
            {/* <Footer2small>
            KONZO VIBEZ (c) {new Date().getFullYear()} All rights reserved.
                     </Footer2small> */}
            
        </FooterContainer>
    )
}

export default Footer2




const Footer2Links = styled.div`
display: flex;
justify-content:center

`;











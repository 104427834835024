import React from 'react'
import {SidebarContainer,
CloseIcon,
Icon,
SidebarWrapper,
SidebarMenu,
NavLinks,
} from './SidebarElements2'
const Sidebar = ({isOpen,toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                             <NavLinks to='/' onClick={toggle}>Home</NavLinks>              
                             <NavLinks to='/recently-added' onClick={toggle}>Recently Added Hits</NavLinks>
                             <NavLinks to='/hot-100' onClick={toggle}>Hot 100</NavLinks>
                             <NavLinks to='/gospel' onClick={toggle}>Gospel Hits</NavLinks>
                             <NavLinks to='/random' onClick={toggle}>Random Hits</NavLinks>
                             <NavLinks to='/random' onClick={toggle}>Search</NavLinks>
                            
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
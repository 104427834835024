import { Nav, NavbarContainer, MobileIcon,NavMenu, NavItem, NavLinks, } from './Navbar.elements'
import {FaBars, } from 'react-icons/fa'
//import logo from '../images/ywd logo.png'

const Navbar = ({toggle}) => {
          
return (
        <>
         <Nav >
            <NavbarContainer>         
                <MobileIcon onClick={toggle}>
                <FaBars/>
                </MobileIcon>
                 <NavMenu>
                 <NavItem>
                             <NavLinks to='/'>Home</NavLinks>
                         </NavItem>
                 <NavItem>
                             <NavLinks to='/artists'>Artists</NavLinks>
                         </NavItem>
                         <NavItem>
                             <NavLinks to='/recently-added'>Recently Added Hits</NavLinks>
                         </NavItem>
                          <NavItem>
                             <NavLinks to='/hot-100'>Hot 100</NavLinks>
                        </NavItem>
                          <NavItem>
                             <NavLinks to='/weekly'>Weekly Hits</NavLinks>
                         </NavItem>
                         <NavItem>
                             <NavLinks to='/monthly'>Monthly Hits</NavLinks>
                         </NavItem>
                         <NavItem>
                             <NavLinks to='/gospel'>Gospel Hits</NavLinks>
                         </NavItem>
                         <NavItem>
                             <NavLinks to='/random'>Random Hits</NavLinks>
                         </NavItem>
                         <NavItem>
                             <NavLinks to='/random'>Search</NavLinks>
                         </NavItem>
                     </NavMenu>
                </NavbarContainer> 
         </Nav>
        </>
    );
};

export default Navbar

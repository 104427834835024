import React, { useEffect, useState } from "react";
import "./Converter.css";
import DownloadButton from "./DownloadButton";
//import Sideview from '../../../Navs/SideView/Sideview'
//import Header from '../../../Navs/SideView/Header'
import Nav3 from '../../../Navs/SideView/Nav3'
//import TopNav from '../../../Navs/SideView/TopNav'
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom'
import firebase from '../../../../firebase'
//import LinearWithValueLabel from './Progress'
//import {Link} from 'react-router-dom'
//import Player from '../Player/Player'
import {
  Container,
  Wrapper,
  Row,
  Column1,
  Column2,
  Card,
  CardContainer,
  CardItems,
  CardWrapper,
  ArtistImg,
  ArtistName,
  HeroContainer,
  LoopVideo,
  VideoBg,
  HeroContent,
  ArtistNameHero,
  Playlist
} from './Convertercss'
//import GoogleAd2 from "../../../GoogleAd2";
//import Fixedad from "../../../FixedAd";
import CompliexAd from "../../../Compliex";
import GoogleAd3 from "../../../GoogleAd3";
import Modal from "./Modal";
function ChartConverter() {
  const { id } = useParams();
  const [chart, setChart ] = useState([]);
  
  
  
  useEffect(() => {
      firebase
      .firestore()
      .collection('charts')
      .doc(id)
      .get()
      .then ((doc) => {
          if (doc.exists) {
              setChart(doc.data());
          }
          else {
              console.log("no such document in firebase 🔥");
            }
      })
      .catch((error) => {
          console.log("Error getting document:", error);
        });
  }, [id])    
  
  return (

    <>
   <Modal /> 
    <Nav3 />
    { chart && (
            <>
      
      <Helmet>
              <title>{chart.song}</title>
            <meta
      name="description"
      content={chart.song}
    />
<meta property="og:title" content={chart.song} />
<meta property="og:image" content={chart.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={chart.image} />
        <meta name="twitter:description" content={chart.song} />
        <meta name="twitter:image" content={chart.image} />
        <link rel="icon" href={`${chart.image}`} />
        </Helmet>
        

        <HeroContainer>
        <LoopVideo>
            <VideoBg src={chart.image} alt="" />
        </LoopVideo>
        <HeroContent>
        <ArtistNameHero>{chart.title}</ArtistNameHero>

        </HeroContent>
        </HeroContainer>

<Container>

<Wrapper>
    <Row>
<Column1>
<Card>
    <CardContainer>
        <CardWrapper >
            <CardItems>
 
<ArtistName style={{fontSize:'50'}}><h1>{chart.artist}</h1></ArtistName>


<ArtistImg src={chart.image} alt="" />
<ArtistName>{chart.title}</ArtistName>

<small style={{color:'green'}}>#{chart.rank} Trending in Kasese</small>
</CardItems>
</CardWrapper>
</CardContainer>
</Card>
</Column1>
<Column2>
          <CardContainer>
        <CardWrapper >
            <CardItems>
            <ArtistName><h1> {chart.song} Mp3 Download</h1></ArtistName>

    <DownloadButton  id2={chart.url} type="mp3" />
    <Playlist><h1>Preview</h1></Playlist>
    <div className="video">
    <iframe
      width="100%"
      height="315"
      src={`https://www.youtube.com/embed/${chart.url}`}
      frameBorder="0"
      allow="accelerometer; autoplay; "
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
  <Playlist><h1>free audio music</h1></Playlist>


            
            
</CardItems>
</CardWrapper>
</CardContainer>
</Column2>
</Row>
</Wrapper>
</Container>   
<GoogleAd3/> 
      <CompliexAd />

    </>
    
        )}
    </>
    

  );
}

export default ChartConverter;


import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    oldies: [],

}

const oldieSlice = createSlice({
    name: "oldie",
    initialState,
    reducers: {
        setOldies: (state, action) => {
            state.oldies  = action.payload

        }, 
    }
})

export const { setOldies } = oldieSlice.actions;
export const selectOldies = (state) => state.oldie.oldies;
export default oldieSlice.reducer;
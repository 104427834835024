import {React, } from 'react'
import './Trending.css'
import {Link} from 'react-router-dom'
import { useSelector} from "react-redux"
import {selectTalents} from "../../../features/talent/talentSlice"
//import firebase from '../../../firebase'
import {CardS, Header, CardContainer, CardItem, CardItems, CardWrapper, ImgWrapper, Img, Text} from './Cardscss'

function Videos() {
    const videos = useSelector(selectTalents)


   

    return (
        
        <div className="Wrapper">
        <CardS>
        <a href='/' text-decoration="none">
            <a href='/' text-decoration="none">
        <Header  text-decoration="none"> <h1>Music Videos </h1> </Header>
        </a>
            <CardContainer>
            { videos && 
                            videos.map((video)=>(

                <CardWrapper key={video.id}>
                    <CardItems>
                    <CardItem>
                    <Link to={'/video/' + video.id} decoration='none' >
                        <ImgWrapper>
                        <Img src={video.image} alt=''/>
            
                    </ImgWrapper>
                        <Text> <h1> {video.title} </h1> </Text>
                        </Link>
                    </CardItem>
                    </CardItems>
                </CardWrapper>
                ))
            }
            
            </CardContainer>
            </a>
        </CardS>
        </div>     
        
    )
}

export default Videos

import {React, } from 'react'
import './Trending.css'
import {Link} from 'react-router-dom'
import { useSelector} from "react-redux"
import {selectGospels} from "../../../features/gospel/gospelSlice"
//import firebase from '../../../firebase'
import {CardS, Header, CardContainer, CardItem, CardItems, CardWrapper, ImgWrapper, Img, Text} from './Cardscss'

function Gospel() {
    const gospel = useSelector(selectGospels)


   

    return (
        
        <div className="Wrapper">
        <CardS>
        <a href='/' text-decoration="none">
            <a href='/' text-decoration="none">
        <Header  text-decoration="none"> <h1>Gospel Music</h1> </Header>
        </a>
            <CardContainer>
            { gospel && 
                            gospel.map((gospel)=>(

                <CardWrapper key={gospel.id}>
                    <CardItems>
                    <CardItem>
                    <Link to={'/gospel/' + gospel.id} decoration='none' >
                        <ImgWrapper>
                        <Img src={gospel.image} alt=''/>
            
                    </ImgWrapper>
                        <Text> <h1>{gospel.title}</h1></Text>
                        </Link>
                    </CardItem>
                    </CardItems>
                </CardWrapper>
                ))
            }
            
            </CardContainer>
            </a>
        </CardS>
        </div>     
        
    )
}

export default Gospel


import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import TextAnimation from './TextAnimation';
import Button from '@mui/material/Button';

const Content = () => {
  const styles = {
    color: "red",
    background: "#00000",
    fontSize: "2px",
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <HeroContainer>
        <LoopVideo />
        <HeroContent>
          <Title>
            <h1>SNOW FM</h1>
          </Title>
          <Desc>
            <TextAnimation />
          </Desc>
          <Radio>
            <iframe
              title="snowfm"
              src="https://sp.streams.ovh/cp/widgets/player/single/?p=8032"
              allow="accelerometer;"
              style={styles}
            ></iframe>
          </Radio>
          <Desc1>
            Embracing Diversity: Enchanting Entertainment with Disability Inclusion!
          </Desc1>
          <Desc2>
            Please click 'Donate' to contribute and help us acquire radio equipments
          </Desc2>
          <Button
            variant="contained"
            href="https://www.paypal.com/donate/?hosted_button_id=UBRGNLABD5ZLE"
            color="success"
          >
            Donate
          </Button>
        </HeroContent>
      </HeroContainer>
    </motion.div>
  );
};

export default Content;

const Radio = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const LoopVideo = styled(motion.div)`
  width: 50%;
  height: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

const HeroContainer = styled(motion.div)`
  background-color: #030617;
  height: 360px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  object-fit: contain;
  position: relative;
  color: #fff;
  &:hover {
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: 768px) {
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (max-width: 992px) {
  }
`;

const HeroContent = styled(motion.div)`
  z-index: 3;
  max-width: 100%;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  top: 0;
  animation: fadeIn 1s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

const Title = styled(motion.div)`
  h1 {
    color: #add8e6;
    font-size: 1.69rem;
    @media only screen and (max-width: 768px) {
      font-size: 1.42rem;
    }
  }
`;

const Desc = styled(motion.div)`
  color: #fff;
  font-size: 30px;
  @media only screen and (max-width: 960px) {
    font-size: 0.81rem;
    display: grid;
    @media only screen and (max-width: 768px) {
      font-size: 0.81rem;
      display: grid;
    }
  }
  
`;

const Desc1 = styled(motion.h6)`
  display: grid;
  color: white;
  font-size: 1.3rem;
  text-align: center;
  text-decoration: none !important;
  @media only screen and (max-width: 768px) {
    font-size: 0.81rem;
    display: grid;
  }
`;
const Desc2 = styled(motion.h6)`
  display: grid;
  color: white;
  font-size: 0.73rem;
  text-align: center;
  text-decoration: none !important;
  @media only screen and (max-width: 768px) {
    font-size: 0.61rem;
    display: grid;
  }
`;
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import axios from "axios";
import { Helmet } from "react-helmet";
import GoogleAd3 from "../../../GoogleAd3";
import CompliexAd from "../../../Compliex";
import styled from "styled-components";
import RecentSlides from "../Silder/RecentSilder";
import firebase from "../../../../firebase";
import Modal from "./Modal";
import Nav3 from "../../../Navs/SideView/Nav3";
import Button from '@mui/material/Button';
import { PlayCircle } from "@mui/icons-material";

const FullWrapper = styled.div`
  background-color: #030617;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  background-color: #030617;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 2xl;
  margin-top: 8px;
  padding: 6px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const ImageContainer = styled.div`
  object-fit: cover;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 8px;
  color: #f2f2f2;
`;

const DownloadSection = styled.div`
  margin-top: 8px;
`;

const DownloadButton = styled.button`
  background-color: #3498db;
  color: #fff;
  padding: 8px 16px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const LoadingContainer = styled.div`
  position: relative;
  padding: 2rem;
  background-color: #131C23;
`;
const StyledImage = styled.img`
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 100%;
  object-cover: cover;
`;
const Desc2 = styled.h1`
display: grid;
color: white;
font-size:0.90rem;
text-align: center;
text-decoration: none!important; 
margin-top: 18px;
@media only screen and (max-width: 768px) {
font-size: 0.95rem;
display: grid;
}

`
const LoadingImageContainer = styled.div`
  position: relative;
  padding-bottom: 75%;
`;

const RecentAddedHitsConverter = () => {
  const { id } = useParams();
  const sanitizedId = encodeURIComponent(id);
  const decodedId = decodeURIComponent(sanitizedId);

  const [recent, setRecent] = useState(null);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  useEffect(() => {
    firebase
      .firestore()
      .collection("recents")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setRecent(doc.data());
        } else {
          console.log("no such document in firebase 🔥");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, [id]);

  const handleDownload = async () => {
    try {
      if (recent && recent.url) {
        const response = await axios.get(
          "https://youtube-mp36.p.rapidapi.com/dl",
          {
            params: { id: recent.url },
            headers: {
              "X-RapidAPI-Key":
                "9310021027msh55a94869f2b1fbdp17a123jsn6fd474a90999",
              "X-RapidAPI-Host": "youtube-mp36.p.rapidapi.com",
            },
            responseType: "blob",
          }
        );

        const originalBlob = response.data;

        const fileSizeInMB = originalBlob.size / (1024 * 1024);
        console.log(`Original File Size: ${fileSizeInMB.toFixed(2)} MB`);

        const reader = new FileReader();

        reader.onloadend = () => {
          const modifiedBlob = new Blob([reader.result], {
            type: "audio/mpeg",
          });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(modifiedBlob);
          link.download = `${recent.title}|Konzovibezbiz.mp3`;
          link.click();

          const modifiedFileSizeInMB = modifiedBlob.size / (1024 * 1024);
          console.log(
            `Modified File Size: ${modifiedFileSizeInMB.toFixed(2)} MB`
          );

          // Set the state to display the download success modal
          setShowDownloadModal(true);

          // Close the modal after 9 seconds
          setTimeout(() => {
            setShowDownloadModal(false);
          }, 9000);
        };

        reader.readAsArrayBuffer(originalBlob);
      }
    } catch (error) {
      console.error("Error downloading:", error);

      // Handle errors as needed
      // ...
    }
  };

  return (
    <FullWrapper>
    <Nav3 />

    {recent ? (
      <Wrapper>
                     <Helmet>
              <title>{recent.title} | MP3 Download | Audio Download</title>
              <meta
                property="og:title"
                content={`${recent.title} | MP3 Download | Audio Download`}
                key="og-title"
              />
              <meta
                property="og:description"
                content={`${recent.title} | MP3 Download | Audio Download`}
                key="og-desc"
              />
              <meta
                property="og:url"
                content={`https://konzovibez.biz/${recent.id}`}
                key="og-url"
              />
              <meta property="og:image" content={recent.image} key="og-image" />
              <meta
                property="og:site_name"
                content="Konzo Vibez"
                key="og-site"
              />
              <meta
                name="twitter:title"
                content={`${recent.title} | MP3 Download | Audio Download`}
                key="tw-title"
              />
              <meta
                name="twitter:description"
                content={`${recent.title} | MP3 Download | Audio Download`}
                key="tw-desc"
              />
              <meta
                name="twitter:image"
                content={recent.image}
                key="tw-image"
              />
              <meta
                name="twitter:card"
                content="summary_large_image"
                key="tw-card"
              />
              <link
                rel="canonical"
                href={`https://konzovibez.biz/${recent.id}`}
              />
              <link rel="icon" href={recent.image} />
            </Helmet>
 
          <ContentWrapper>
            <ImageContainer>
              <StyledImage
                src={recent.image}
                alt=""
                width={20}
                height={20}
                unoptimized
              />{" "}
            </ImageContainer>
            <Title>{recent.title}</Title>
            <div>
            <Button variant="contained" href={`https://www.youtube.com/watch?v=${recent.url}`} color="success" size="small" startIcon={<PlayCircle />}>
  Play Song
</Button>
 

            <DownloadSection>
                <p>Download the MP3 version of the song below:</p>
                <iframe
                  class="button-api-frame"
                  src={`https://mp3api.ytjar.info/?id=${recent.url}&c=ffff&b=0000ff&t`}
                  width="150px"
                  height="40px"
                ></iframe>
              </DownloadSection>

              <DownloadSection>
                <p style={{color:'yellow'}}> NOTE:  You may need to turn off the VPN to see the downloading button</p>

                
              

                {/* <DownloadButton onClick={handleDownload}>
                  Download MP3
                </DownloadButton> */}
              </DownloadSection>
            </div>
          </ContentWrapper>
        </Wrapper>
      ) : (
        <LoadingContainer>
          <SkeletonTheme color="#131C23" highlightColor="#131C23">
            <div>
            <Desc2> Loading.... </Desc2>
                  <ImageContainer>

      <StyledImage
                src="/app.jpg"
                alt=""
                width={20}
                height={20}
                unoptimized
              />{" "}
            </ImageContainer>

              <div>
                <LoadingImageContainer>
                  <Skeleton height={100} />
                </LoadingImageContainer>
                <Skeleton height={40} count={3} />
              </div>
            </div>
          </SkeletonTheme>
        </LoadingContainer>
      )}

      {/* Modal for download success */}
      <Modal
        isOpen={showDownloadModal}
        closeModal={() => setShowDownloadModal(false)}
      />
      <Desc2> Beyond Barriers: Celebrating the Diverse Experiences of People with Disabilities. Please click 'Donate' to contribute and help us acquire radio equipments </Desc2>


<Button variant="contained" href='https://www.paypal.com/donate/?hosted_button_id=UBRGNLABD5ZLE' color="success">
Donate
</Button>   
      <h6 style={{color:'white', margin:'10px'}}>Check out the current Music</h6>

      <RecentSlides />

      <GoogleAd3 />
      <CompliexAd />
      </FullWrapper>
  );
};
export default RecentAddedHitsConverter;

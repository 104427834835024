import React from 'react'
import styled from 'styled-components'
import {Helmet} from 'react-helmet'
function About() {
    return (
        <> 
        <Helmet>
    <title>Konzo Vibez | Showcasing Kasese & Bundibugyo Music | Snow FM Kasese</title>
        

<meta name="keywords" content="Discover the vibrant musical talents of Kasese and Bundibugyo artists on Konzo Vibez. Enjoy the best local music and explore an extensive platform dedicated to promoting regional artists. Tune in to Snow FM Kasese, our online radio station advocating for disability rights. Join our community today" />
<meta

  name="description"
  content="Discover the vibrant musical talents of Kasese and Bundibugyo artists on Konzo Vibez. Enjoy the best local music and explore an extensive platform dedicated to promoting regional artists. Tune in to Snow FM Kasese, our online radio station advocating for disability rights. Join our community today"
  
/>
<link rel="icon" href="/logo192.png" />

        </Helmet>
     
        <Container>
        <Logo> 
        <Img>
                <a href='/'>
                    <img src="/konzologo.png" alt="/" />
                 </a>
                 </Img>
        </Logo>
            <Content>
            <strong> KONZO VIBEZ </strong>
<p>Konzo Vibez is the first fully equipped entertainment online platform in Kasese, a four in one website with services which includes:</p>
<ol type="1">
<li> <strong>Konzo Hits</strong></li>
 A music Downloading Platform mainly for Music made in Kasese. This initiative is to increase viewers on the Artists' youtube channels. 
In the initial stage, Konzo Hits will not save any songs on its servers, all the music is directly from youtube channels of artists and this means that each download of a particular song on Konzo Hits Platform will generate youtube video viewer hence increasing their revenue on youtube.
Next year, we will partner with Airtel and MTN mobile money services to enable us to charge users per download they make and the fee they pay will be directly be sent to the Artists' Mobile Money numbers.
Under this initiative, artists will also be able to sell their labeled commodities on the site.
We only aim to make Kasese Artists richer via this online initiative.

<li><strong>Rwenzori Tabloid</strong></li>
This is news blog topping up the trending news in Kasese.

<li><strong>Snow FM</strong> </li>
Broadcasting 24/7 live music mixes by various DJs in Kasese 

<li> <strong>KW TV</strong> </li>
The first 24/7 live broadcasting TV in Kasese, as meanwhile this will be hosted Online.
</ol>
<p>We are establishing physical structures and frequencies in Kasese.</p>

<p>www.konzovibez.biz is crafted by KW SOFTWARE</p>
<p>Member of SNOW MEDIA SERVICES</p>

<p Management />
<p K-MEDIA SERVICES />
            </Content>
            </Container>

        </>
    )
}

export default About


const Img = styled.div`
justify-content: center;

img {
  width:100%;
height:150px;
@media screen and (max-width:768px){
height: 80px;
}


}

`
const Logo = styled.div`
justify-content: center;
`

const Content = styled.div`
font-size:20px;
color: black;
margin:50px;
`

const Container = styled.div`
background-image: url("/white.jpg") ;
background-repeat: repeat;

//background: url('./images/ywd logo.PNG') center center/cover no-repeat;
height: 100%;
width: 100%;
/* margin-left: 50px;
margin-right: 50px; */
display: flex;
justify-content: center;
flex-direction:column;
align-items:center;
cursor: pointer;
border-radius: 4px;
object-fit: contain;
position: relative;
color: #fff;
& :hover {

    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {

}
`;

import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const Nav = styled.nav`
background: #008EE1;
height: 40px;
display: flex;
justify-content: center;
align-items: center;
font-size: 0.92rem;
position: sticky;
top: 0;
z-index: 999;
//display: flex;
//background: rgba(0, 0, 0, 0.5);
@media screen and (max-width: 768px) {
width: 100%;
}
`; 

export const NavbarContainer = styled.div`
display: flex;
justify-content: space-between;
height: 80px;
//z-index: 1;
//width: 100%;
//padding: 0 24px;
//max-width: 1100px;


`; 




export const MobileIcon = styled.div`
display: none;
@media only screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background: #008EE1;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    color:white;
    cursor: pointer;
}
`;
export const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
//position: absolute;
text-align: center;
@media only screen and (max-width:960px) {
display: grid;
flex-direction: column;
width: 100%;
height: 550px;
position: absolute;
top: 60px;
left: ${({click}) => (click ? 0 :' -100%' )};
opacity: 1;
transition: all 0.5s ease;
background: black;
}
`;
export const NavItem = styled.li`
height: 80px;
border-bottom: 2px solid transparent;
display: flex;
//position: relative;
//justify-content:end;
//align-items: center;
& hover {
    border-bottom: 2px solid #F50057;
}

@media  screen and (max-width: 768px){
    width: 100%;
& hover {
    border: none;
}
}
`;
export const NavLinks = styled(Link)`

  color: white;
    font-size: 0.79rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.1rem 0.78rem;
  height: 100%;
  margin-right: 30px;
  margin-top: 10px;


@media screen and (max-width: 960px){
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;


}
`;

/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./DownloadButton.css";
import styled from 'styled-components'
import LinearWithValueLabel from './Progress'
import GoogleAd2 from "../../../GoogleAd2";

function DownloadButton({ id2, type }) {
  return (
    <Div style={{background:"#030617"}} >
{/* <Note> <h6>please refresh the page, when you see error, and select the mp3 quality of your choice</h6></Note> */}
<LinearWithValueLabel />
{/* <Note>Tap to Download</Note> */}

<iframe style={{background:"#030617"}} class="button-api-frame" src={`https://mp3api.ytjar.info/?id=${id2}&c=183A1D&b=498743&t`}
 width="100%" height="100%"  >
 </iframe>
{/* <GoogleAd2 />   */}
      
    </Div>
    
  );
}

export default DownloadButton;

const Div =styled.div`
overflow: hidden;
height: 100px;
position:relative;
width:100%;
color: #030617 !important; 

`

import styled from "styled-components";

export const CardS = styled.div`
  background-color: #030617;
  padding: 0.2rem;
  background-repeat: repeat;
  text-decoration: none !important;
  margin: 0;

  a {
    text-decoration: none !important;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0px 10px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const CardWrapper = styled.div`
  position: relative;
`;

export const CardItems = styled.ul`
  text-decoration: none;
  list-style: none;
`;

export const CardItem = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
`;

export const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
`;

export const Img = styled.img`
  border-radius: 8px; /* Adjusted border-radius for a more subtle effect */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
`;

export const Text = styled.div`
  text-decoration: none;
  text-align: center;
  margin-top: 8px;

  h1 {
    font-size: 1rem; /* Increased font size for better readability */
    color: white;
    text-decoration: none !important;
    font-weight: 700 !important;
  }
`;

export const Header = styled.h1`
  text-decoration: none;
  text-align: center;
  font-weight: 900;
  font-size: 0.93rem;
  color: white;
  margin-bottom: 16px; /* Increased margin for better separation */
`;

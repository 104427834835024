// FeaturedSlides.js
import React from 'react';
import { useSelector } from 'react-redux';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import FeaturedPostCard from './FeaturedPostCard';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 8px;
  background-color: #030617; /* Replace with your desired color */
`;

const CarouselContainer = styled(Carousel)`
  & .react-multi-carousel-item {
    padding: 0 4px; /* Replace with your desired padding */
  }
`;

const SkeletonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, 1fr));
  grid-gap: 16px; /* Replace with your desired gap */
`;

const SkeletonWrapper = styled.div`
  position: relative;
  padding: 8px;

  &:hover {
    background-color: #f1f1f1; /* Replace with your desired background color */
  }
`;

const FeaturedSlides = () => {
  const recents = useSelector((state) => state.recent.recents);

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1024 }, items: 5 },
    desktop: { breakpoint: { max: 1024, min: 768 }, items: 3 },
    tablet: { breakpoint: { max: 768, min: 640 }, items: 2 },
    mobile: { breakpoint: { max: 640, min: 0 }, items: 1 },
  };

  return (
    <Container>
      {recents && recents.length > 0 ? (
        <CarouselContainer infinite autoPlay responsive={responsive}>
          {recents.map((recent) => (
            <FeaturedPostCard key={recent.id} recent={recent} />
          ))}
        </CarouselContainer>
      ) : (
        <SkeletonGrid>
          {[...Array(6)].map((_, index) => (
            <SkeletonWrapper key={index}>
              <Skeleton height={100} />
              <div className="text-sm text-text text-center font-extrabold mt-2">
                <Skeleton width={150} />
              </div>
            </SkeletonWrapper>
          ))}
        </SkeletonGrid>
      )}
    </Container>
  );
};

export default FeaturedSlides;

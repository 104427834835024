/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import styled from 'styled-components'
import TextAnimation from './TextAnimation';
import {FaFacebook, FaInstagram, FaTwitter, FaYoutube, FaLinkedin, FaWhatsapp} from 'react-icons/fa'
const Content =() => {
    const styles = {
        color: "red",
        background: "#0f0",
        fontSize: "32px"


    };
    
    return (
        <>
             <HeroContainer>
        <LoopVideo>
        </LoopVideo>
        <HeroContent>
            <Title>SNOW FM</Title>
            <Desc>WE ARE THE <TextAnimation /> RADIO STATION AROUND K-TOWN </Desc>

            <iframe src="https://sp.streams.ovh/cp/widgets/player/single/?p=8032" style={styles}></iframe>
                    <SocialMedia>
        <SocialMediaWrap>
        <SocialIcons>
            <SocialLogo>
                Drop us a line 
                            </SocialLogo>
        <SocialIconsLinks href='www.facebook.com/SnowFmKasese' target='_blank' aria-label='Facebook'> <FaFacebook /> </SocialIconsLinks>
        <SocialIconsLinks href='/' target='_blank' aria-label='Whatsapp'> <FaWhatsapp /> </SocialIconsLinks>
        <SocialIconsLinks href='/' target='_blank' aria-label='Instagram'> <FaInstagram /> </SocialIconsLinks>
        <SocialIconsLinks href='www.twitter.com/SnowFmKasese' target='_blank' aria-label='Twitter'> <FaTwitter /> </SocialIconsLinks>
        <SocialIconsLinks href='/' target='_blank' aria-label='Youtube'> <FaYoutube /> </SocialIconsLinks>
        <SocialIconsLinks href='/' target='_blank' aria-label='Linkedin'> <FaLinkedin /> </SocialIconsLinks>
        </SocialIcons>
        </SocialMediaWrap>
        </SocialMedia>
        <Desc2>Also Available on on mobile and web radio dictionaries</Desc2>
        <Desc2>Our Android and iOS apps are coming on all app stores soon </Desc2>
        </HeroContent>
        </HeroContainer>
        </>
    )
    }

export default Content



 const LoopVideo = styled.div`
//object-fit: cover;
width: 50%;
height: 50%;
position: absolute;
//z-index: -1;
top: 0;
bottom: 0;
right: 0;
left: 0;
overflow: hidden;
@media only screen and (max-width:768px){
    width: 100%;
    height: 100%;
}
`
const HeroContainer = styled.div`
background-image: url("/bg.png") ;
background-repeat: repeat;

//background: url('./images/ywd logo.PNG') center center/cover no-repeat;
height: 700px;
width: 100%;
/* margin-left: 50px;
margin-right: 50px; */
display: flex;
justify-content: center;
flex-direction:column;
align-items:center;
cursor: pointer;
border-radius: 4px;
object-fit: contain;
position: relative;
color: #fff;
& :hover {

    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {

}
`;

const HeroContent = styled.div`
z-index: 3;
max-width:100%;
position: absolute;
padding: 8px 24px;
display: flex;
flex-direction:column;
align-items:center;
font-size:3rem;
white-space:nowrap;
@media screen and (max-width:768px){
max-width: 100%;
}

/* :before {
    position: absolute;
color:yellow;
    font-size: 10px;
   position: center;  */
   
    
`;
const Title = styled.h1`
color:#DB5D5D;
font-size:4rem;
@media only screen and (max-width: 768px) {
font-size: 2rem;
}

`
const Desc = styled.h1`
color: #fff;
font-size:40px;
@media only screen and (max-width: 768px) {
font-size: 1rem;
display: grid;
}

`
const Desc2 = styled.h6`
display: grid;
color: white;
font-size:1rem;
@media only screen and (max-width: 768px) {
font-size: 0.61rem;
display: grid;
}

`

const SocialMedia =styled.section`
max-width: 1000px;
width: 100%;
`

const SocialMediaWrap =styled.div`
display: flex;
justify-content: space-between;
align-items: center;
max-width: 1100px;
margin: 40px auto 0 auto;
@media only screen and (max-width: 820px) {
flex-direction: column;
}
`

const SocialLogo =styled.p`
color: #fff;
justify-content: flex-start;
text-decoration: none;
font-size:1rem;
display: flex;
align-items: center;
margin-bottom: 16px;
`

const SocialIcons = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 240px;

`

const SocialIconsLinks =styled.a`
color: #fff;
font-size:24px;
`

import {React, } from 'react'
import './Trending.css'
import {Link} from 'react-router-dom'
import { useSelector} from "react-redux"
import {selectMusiks} from "../../../features/musik/musikSlice"
//import firebase from '../../../firebase'
import {CardS, Header, CardContainer, CardItem, CardItems, CardWrapper, ImgWrapper, Img, Text} from './Cardscss'

function Gospel() {
    const musiks = useSelector(selectMusiks)


   

    return (
        
        <div className="Wrapper">
        <CardS>
        <a href='/' text-decoration="none">
            <a href='/' text-decoration="none">
        <Header  text-decoration="none"> <h1>Random Hits</h1></Header>
        </a>
            <CardContainer>
            { musiks && 
                            musiks.map((musik)=>(

                <CardWrapper key={musik.id}>
                    <CardItems>
                    <CardItem>
                    <Link to={'/song/' + musik.id} decoration='none' >
                        <ImgWrapper>
                        <Img src={musik.image} alt=''/>
            
                    </ImgWrapper>
                        <Text> <h1>{musik.title} </h1> </Text>
                        </Link>
                    </CardItem>
                    </CardItems>
                </CardWrapper>
                ))
            }
            
            </CardContainer>
            </a>
        </CardS>
        </div>     
        
    )
}

export default Gospel


import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    talents: [],

}

const talentSlice = createSlice({
    name: "talent",
    initialState,
    reducers: {
        setTalents: (state, action) => {
            state.talents  = action.payload

        }, 
    }
})

export const { setTalents } = talentSlice.actions;
export const selectTalents = (state) => state.talent.talents;
export default talentSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    newis: [],

}

const newiSlice = createSlice({
    name: "newi",
    initialState,
    reducers: {
        setNewis: (state, action) => {
            state.newis  = action.payload

        }, 
    }
})

export const { setNewis } = newiSlice.actions;
export const selectNewis = (state) => state.newi.newis;
export default newiSlice.reducer;
import React,{useState} from 'react'
import Sidebar from '../sidebar/Sidebar2'
import Navbar from '../navbars/Navbar'
const Nav2 = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {
        setIsOpen(!isOpen);
    }
    return (
        <>
        <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Navbar toggle={toggle}/>
            
        </>
    )
}

export default Nav2

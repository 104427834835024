import {React, } from 'react'
import './Trending.css'
import {Link} from 'react-router-dom'
import { useSelector} from "react-redux"
import {selectOldies} from "../../../features/oldies/oldieSlice"
//import firebase from '../../../firebase'
import {CardS, Header, CardContainer, CardItem, CardItems, CardWrapper, ImgWrapper, Img, Text} from './Cardscss'

function Oldies() {
    const oldies = useSelector(selectOldies)


   

    return (
        
        <div className="Wrapper">
        <CardS>
        <a href='/' text-decoration="none">
            <a href='/' text-decoration="none">
        <Header  text-decoration="none"> <h1>Oldies Hits</h1></Header>
        </a>
            <CardContainer>
            { oldies && 
                            oldies.map((oldie)=>(

                <CardWrapper key={oldie.id}>
                    <CardItems>
                    <CardItem>
                    <Link to={'/oldies/' + oldie.id} decoration='none' >
                        <ImgWrapper>
                        <Img src={oldie.image} alt=''/>
                        </ImgWrapper>
                        <Text> <h1>{oldie.title}</h1></Text>
                        </Link>
                    </CardItem>
                    </CardItems>
                </CardWrapper>
                ))
            }
            
            </CardContainer>
            </a>
        </CardS>
        </div>     
        
    )
}

export default Oldies



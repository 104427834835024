// components/ProgramSchedule.js

import React from 'react';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
 background-color: #030617;
   margin: auto;
  padding: 8px;
`;

const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.375rem;
  color: white;
`;

const ScheduleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const ScheduleItem = styled.div`
  background-color: #161c2f;
  padding: 1rem;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
`;

const ProgramSchedule = () => {
  const scheduleData = [
    { day: 'Monday', program: 'Evening Pulse with Professor Ronnie Kevin', time: '8 PM - 9:30 PM' },
    { day: 'Wednesday', program: 'Rwenzori Breeze with Eddy King', time: '8 PM - 10:00 PM' },
    { day: 'Friday', program: 'Snow MIX show with DJ Riley Kyle', time: '10 PM - 12 AM' },
    { day: 'Saturday', program: 'Saturday Mashup with MC Senior B', time: '5 PM - 7 PM' },
    { day: 'Saturday', program: 'Weekend Beats with DJ Dolla', time: '8 PM - 12 AM' },
    { day: 'Sunday', program: 'Snow Light with Rodgers Price', time: '12 PM - 2 PM' },
    { day: 'Sunday', program: 'Request Show with Mukonzo 256', time: '5 PM - 7 PM' },
  ];

  return (
    <Container>
      <Title>Snow FM Kasese Program Schedule</Title>

      <ScheduleGrid>
        {scheduleData.map((item, index) => (
          <ScheduleItem key={index}>
            <p style={{ fontWeight: 'bold' }}>{item.day}</p>
            <p style={{ fontSize: '0.875rem' }}>{item.program}</p>
            <p style={{ fontSize: '0.75rem' }}>{item.time}</p>
          </ScheduleItem>
        ))}
      </ScheduleGrid>
    </Container>
  );
};

export default ProgramSchedule;

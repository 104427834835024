import React, { useEffect, useState } from "react";
import "./Converter.css";
import DownloadButton from "./DownloadButton";
//import styled from 'styled-components'
//import Sideview from '../../../Navs/SideView/Sideview'
//import Header from '../../../Navs/SideView/Header'
import Nav3 from '../../../Navs/SideView/Nav3'
//import TopNav from '../../../Navs/SideView/TopNav'
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom'
import firebase from '../../../../firebase'
//import LinearWithValueLabel from './Progress'
//import {Link} from 'react-router-dom'
//import Player from '../Player/Player'
import {
  Container,
  Wrapper,
  Row,
  Column1,
  Column2,
  Card,
  CardContainer,
  CardItems,
  CardWrapper,
  ArtistImg,
  ArtistName,
  HeroContainer,
  LoopVideo,
  VideoBg,
  HeroContent,
  Playlist
} from './Convertercss'
// import GoogleAd from "../../../GoogleAd";
 import GoogleAd3 from "../../../GoogleAd3";
// import Fixedad from "../../../FixedAd";
 import CompliexAd from "../../../Compliex";
import Modal from "./Modal";
function RandomConverter() {
  const { id } = useParams();
  const [musik, setMusik ] = useState([]);
  
  
  
  useEffect(() => {
      firebase
      .firestore()
      .collection('musiks')
      .doc(id)
      .get()
      .then ((doc) => {
          if (doc.exists) {
              setMusik(doc.data());
          }
          else {
              console.log("no such document in firebase 🔥");
            }
      })
      .catch((error) => {
          console.log("Error getting document:", error);
        });
  }, [id])    
  
  return (

    <>
   <Modal /> 
    
    <Nav3 />
    { musik && (
            <>
      
      <Helmet>
      <title>{musik.title}</title>
            <meta
      name="description"
      content={musik.title}
    />
      <meta property="og:title" content={musik.title} />
      <meta property="og:image" content={musik.image} />
        
     <link rel="icon" href={`${musik.image}`} />


        </Helmet>
        
        <HeroContainer>
        <LoopVideo>
            <VideoBg src={musik.image} alt="" />
        </LoopVideo>
        <HeroContent>
        
        </HeroContent>
        </HeroContainer>
        <DownloadButton  id2={musik.url} type="mp3" />

<Container>

<Wrapper>
    <Row>
<Column1>
<Card>
    <CardContainer>
        <CardWrapper >
            <CardItems>
     

<ArtistImg src={musik.image} alt="" />
<ArtistName>{musik.title}</ArtistName>
</CardItems>
</CardWrapper>
</CardContainer>
</Card>
</Column1>
<Column2>
          <CardContainer>
        <CardWrapper >
            <CardItems>
            <ArtistName> <h1> {musik.title} Mp3 Download</h1></ArtistName>
    <DownloadButton  id2={musik.url} type="mp3" />
  
  

            
</CardItems>
</CardWrapper>
</CardContainer>
</Column2>
</Row>
</Wrapper>
</Container>
<GoogleAd3/> 
<CompliexAd />


      
    </>
    
        )}
    </>
    

  );
}

export default RandomConverter;


import React,{useEffect, } from 'react';
import {useLocation, Switch, Route,  } from "react-router-dom";
import photos from './components/pages/photos'
import Home from './components/pages/Home'
import GlobalStyle from './globalStyles' 
import Artists from './components/pages/Artists'
import Privacy from './components/pages/Privacy'
import Terms from './components/pages/Terms'
import About from './components/pages/About'
import Upload from './components/pages/Upload'
import Advertise from './components/pages/Advertise'
import Song from './components/pages/Song'
import artistsDetails from './components/audio/Details'
import homepage from './components/snowfm/homepage';
import homepage2 from './components/kwtv/homepage2';
import Footer2 from './components/Footer2'
//import Converter2 from './components/yt converter/Converter';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga';
//import {Helmet} from 'react-helmet'
import Converter from './components/audio/Songs/Mp3Converter/Converter'
import OldiesConverter from './components/audio/Songs/Mp3Converter/OldiesConverter'
import GospelConverter from './components/audio/Songs/Mp3Converter/GospelConverter'
import TalentConverter from './components/audio/Songs/Mp3Converter/TalentConverter'
import Nonstops from './components/audio/Songs/Mp3Converter/Converter2'
//import GoogleAd from './components/GoogleAd';
//import FooterAd from './components/GoogleAd';
//import Adsense from './components/Adsense';
//import Ads from './components/pages/Ads'
import ScrollToTop from './components/ScrollToTop'
import NonstopPage from './components/pages/NonstopPage';
import OldiesPage from './components/pages/OldiesPage';
import GospelPage from './components/pages/GospelPage';
import NewTalentPage from './components/pages/NewTalentPage';
import RecentlyAddedHitsConverter from './components/audio/Songs/Mp3Converter/RecentAddedHitsConverter';
import RecentAddedHitsPage from './components/pages/RecentlyAddedHitsPage';
import RandomPage from './components/pages/RandomPage';
import RandomConverter from './components/audio/Songs/Mp3Converter/RandomConverter'
import ChartConverter from './components/audio/Songs/Mp3Converter/ChartConverter';
import Top100Page from './components/pages/Top100';
//import Keywords from './components/pages/Keywords'
//import Jcp from './components/pages/jcp files/src/App';
//import Sidebar from './components/Navs/index'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoConverter from './components/audio/Songs/Mp3Converter/VideoConverter';
import VideosPage from './components/pages/VideosPage';
import NewConverter from './components/audio/Songs/Mp3Converter/NewConverter';
//import { Helmet } from 'react-helmet';


function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-C8BTBL2H7M');
    ReactGA.pageview('/App');
    //ReactGA.pageview(window.location.pathname + window.location.search);

  }, [] ) 
  return (
    
    <>
            
      <ScrollToTop />
      <GlobalStyle />
    
     <Switch location={location} key={location.pathname}>
     <Route path='/kw-tv' component={homepage2 } />
     <Route path='/konzohits' component={Song } />
     <Route path ='/videos' component={VideosPage} />
     <Route path='/hot-100' component={Song } />
     <Route path='/snow-fm' component={homepage} />
     <Route path='/photos' component={photos} />
     <Route path ='/artists' component={Artists} />
     <Route path ='/konzohits' component={Artists} />
     {/* <Route path ='/artistsDetails/:id' component={artistsDetails} /> */}
     <Route path ='/music/:id' component={Converter} />
     <Route path ='/station/:id' component={NewConverter} />
     <Route path ='/video/:id' component={VideoConverter} />
     <Route path ='/ziki/:id' component={ChartConverter} />
     <Route path ='/oldies/:id' component={OldiesConverter} />
     <Route path ='/gospel/:id' component={GospelConverter} />
     <Route path ='/musics/:id' component={RecentlyAddedHitsConverter} />
     <Route path ='/radio/:id' component={TalentConverter} />
     <Route path ='/song/:id' component={RandomConverter} />
     <Route path ='/tv/:id' component={Nonstops} />
     <Route path ='/channels/' component={NonstopPage} />
     <Route path ='/radios/' component={NewTalentPage} />
     <Route path ='/song/' component={RandomPage} />
     <Route path ='/musics/' component={RecentAddedHitsPage} />
     <Route path ='/oldies' component={OldiesPage} />
     <Route path ='/gospel' component={GospelPage} />
     <Route path ='/hot10' component={Top100Page} />
     <Route path='/privacy-policy' component={Privacy} />
     <Route path='/terms-and-conditions' component={Terms} />
     <Route path='/about' component={About} />
     <Route path='/contacts' component={Upload} />
     <Route path='/Advertise' component={Advertise} />
     <Route path='/shop' component={() => { 
     window.location.href = 'https://flutterwave.com/store/konzovibez'; 
     return null;
}}/>
     <Route path='/jcp' component={() => { 
     window.location.href = 'https://joshcreativeprograma.netlify.app/'; 
     return null;
}}/>

<Route path='/konzomobi' component={() => { 
     window.location.href = 'https://drive.google.com/file/d/1sE13i_xxOaFX8EM4sEOhYR2j-ovxvMMj/view?usp=sharing'; 
     return null;
}}/>

<Route path='/konzomobiapp' component={() => { 
     window.location.href = 'https://drive.google.com/file/d/1sE13i_xxOaFX8EM4sEOhYR2j-ovxvMMj/view?usp=sharing'; 
     return null;
}}/>
<Route path='/tabloid' component={() => { 
     window.location.href = 'https://snowtabloid.com'; 
     return null;
}}/>
<Route path='/lightfm' component={() => { 
     window.location.href = 'https://sp14.instainternet.com/8158/stream;'; 
     return null;
}}/>
<Route path='/donate' component={() => { 
     window.location.href = 'https://gogetfunding.com/empower-kasese-through-snow-fm-a-call-for-support/'; 
     return null;
}}/>
<Route path='/whatsapp' component={() => { 
     window.location.href = 'https://chat.whatsapp.com/Inx2V5poGhj7DcDknviNsc'; 
     return null;
}}/>

     <Route path ='/' component={Home}/>

    </Switch>
    <Footer2 />

          </>
  );
}

export default App;

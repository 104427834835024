import React from 'react';
import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { FiMusic  } from 'react-icons/fi';
import { MdOutlineRadio } from "react-icons/md";
import { PiTelevisionSimpleThin } from "react-icons/pi";
import logo from '../../../images/konzomobi.png'; // Make sure to replace with your logo path

const Navbar = () => {
  return (
    <Nav>
      <NavbarContainer>
        <Logo to="/">
          <img src={logo} alt="Logo" />
        </Logo>
        <NavMenu>
          <NavItem>
            <NavLinks to="/">
              <FaHome />
              Home
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="/konzohits">
              <FiMusic />
              Konzo Music
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="/radios">
              <MdOutlineRadio />
              Radio Stations
            </NavLinks>
          </NavItem>
          <NavItem>
            <NavLinks to="/channels">
              <PiTelevisionSimpleThin />
              TV Stations
            </NavLinks>
          </NavItem>
        </NavMenu>
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;

const Nav = styled.nav`
  background: #161c2f;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;

const Logo = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  img {
    height: 40px;
    width: auto;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -16px;
  width: 100%;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

const NavItem = styled.li`
  display: flex;

  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

const NavLinks = styled(LinkR)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid rgb(126, 253, 45);
  }

  &:hover {
    transition: all 0.3s ease-in-out;
    background: #333;
    transform: scale(1.05);
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
    width: 100%;
    padding: 1rem 0;
  }
`;

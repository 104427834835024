import React from 'react';

export default class CompliexAd extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
<div style={{overflow: 'hidden'}}>
  <ins
    className="adsbygoogle"
    style={{display: 'block', background:"#030617"}}
    data-ad-client="ca-pub-2968429081843062"
    data-ad-format="autorelaxed"
     data-ad-slot="7184480558"
  />
</div>  
 );
  }
}
// RecentSlides.js
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { setRecents } from '../../../../features/recent/recentSlice';
import FeaturedSlides from './FeaturedSlides';

function RecentSlides() {
  const dispatch = useDispatch();
  const [randomRecents, setRandomRecents] = useState([]);

  useEffect(() => {
    const db = firebase.firestore();
    const recentCollection = db.collection('recents');

    // Fetch all documents from the 'recents' collection
    recentCollection.get().then((querySnapshot) => {
      // Convert the documents to an array
      const allRecents = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Shuffle the array to randomize the order
      const shuffledRecents = shuffleArray(allRecents);

      // Define the number of random items you want to show
      const numberOfRandomItems = 3; // You can adjust this as needed

      // Take the first N items as random items
      const randomRecentsItems = shuffledRecents.slice(0, numberOfRandomItems);

      // Dispatch the random items to the Redux store
      dispatch(setRecents(randomRecentsItems));

      // Set the random recents for local state if needed
      setRandomRecents(randomRecentsItems);
    });
  }, [dispatch]);

  // Function to shuffle an array using Fisher-Yates algorithm
  function shuffleArray(array) {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }

  return <FeaturedSlides recents={randomRecents} />;
}

export default RecentSlides;

import { FaTimes} from 'react-icons/fa'
import styled from 'styled-components'
import {Link} from 'react-router-dom'



export const SidebarContainer = styled.aside`
position: fixed;
z-index: 999;
width: 100%;
height: 80%;
background: #008EE1;
display: grid;
align-items: center;
top: 15;
left: 0;
transition: 0.3s ease-in-out;
opacity: ${({ isOpen })=>(isOpen ? '100%' : '0')};
top: ${({isOpen})=>(isOpen ? '0' : '-100%')};


`
export const CloseIcon = styled(FaTimes)`
color:#fff;

`


export const Icon = styled.div`
position: absolute;
top:1.2rem;
right:1.5rem;
background: transparent;
font-size:2rem; 
cursor: pointer;
outline: none;

`

export const SidebarWrapper = styled.div`
color: #fff;
`

export const NavLinks = styled(Link)`
display: flex;
font-size: 1rem;
text-decoration:none;
list-style: none;
transition: all 0.2s ease-in-out;
text-decoration:none;
color:#fff;
cursor:pointer;
& hover {
    color: #0b1f71;
    transition: all 0.2s ease-in-out;
}
`

export const SidebarMenu = styled.ul`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(9,50px);
text-align: center;
@media only screen and (max-width:480px) {
grid-template-rows: repeat(6,60px);

}
`
import React, { useEffect, useState, useRef } from "react";
import "./Converter.css";
import Nav3 from '../../../Navs/SideView/Nav3';
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import firebase from '../../../../firebase';
import styled from "styled-components";
import GoogleAd3 from "../../../GoogleAd3";
import CompliexAd from "../../../Compliex";
import Modal from "./Modal";
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';

const Container = styled.div`
  background-color: #030617;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background: #1c2237;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const CardWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const CardItems = styled.div`
  margin: 20px 0;
`;

const ArtistImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
`;

const ArtistName = styled.h1`
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 10px;
`;

const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const FullscreenButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
`;
const AdContainer = styled.div`
  width: 100%;
  max-width: 728px;
  margin: 20px 0;
`;

function TVPlayer() {
  const { id } = useParams();
  const [tv, setTv] = useState(null);
  const playerRef = useRef(null);

  useEffect(() => {
    firebase
      .firestore()
      .collection('channels')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setTv(doc.data());
        } else {
          console.log("No such document in Firebase 🔥");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, [id]);

  const handleFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.request(playerRef.current.wrapper);
    }
  };

  return (
    <>
      <Modal />
      <Nav3 />
      {tv && (
        <>
          <Helmet>
            <title>{tv.title} Live Stream</title>
            <meta name="description" content={tv.title} />
            <meta property="og:title" content={tv.title} />
            <meta property="og:image" content={tv.image} />
            <link rel="icon" href={`${tv.image}`} />
          </Helmet>
          
          <Container>
            <CardContainer>
              <CardWrapper>
                <CardItems>
                  <ArtistImg src={tv.image} alt={tv.title} />
                  <ArtistName>{tv.title}</ArtistName>
                </CardItems>
                <CardItems>
                  <VideoWrapper>
                    <ReactPlayer
                      ref={playerRef}
                      url={tv.url}
                      controls
                      playing
                      width="100%"
                      height="100%"
                    />
                    <FullscreenButton onClick={handleFullScreen}>
                      Fullscreen
                    </FullscreenButton>
                  </VideoWrapper>
                </CardItems>
              </CardWrapper>
            </CardContainer>
            <AdContainer>
              <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-2968429081843062"
                data-ad-slot="8703718386"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </AdContainer>

            <AdContainer>
              <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-2968429081843062"
                data-ad-slot="8456694735"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </AdContainer>
                      </Container>
        </>
      )}
    </>
  );
}

export default TVPlayer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    artists: [],

}

const artistSlice = createSlice({
    name: "artist",
    initialState,
    reducers: {
        setArtists: (state, action) => {
            state.artists  = action.payload

        }, 
    }
})

export const { setArtists } = artistSlice.actions;
export const selectArtists = (state) => state.artist.artists;
export default artistSlice.reducer;
import {React, } from 'react'
import './Trending.css'
import {Link} from 'react-router-dom'
import { useSelector} from "react-redux"
import {selectTalents} from "../../../features/talent/talentSlice"
//import firebase from '../../../firebase'
import {CardS, Header, CardContainer, CardItem, CardItems, CardWrapper, ImgWrapper, Img, Text} from './Cardscss'

function NewTalent() {
    const talents = useSelector(selectTalents)


   

    return (
        
        <div className="Wrapper">
        <CardS>
        <a href='/' text-decoration="none">
            <a href='/' text-decoration="none">
        <Header  text-decoration="none"> Listen to Radio Stations in Western Uganda </Header>
        </a>
            <CardContainer>
            { talents && 
                            talents.map((talent)=>(

                <CardWrapper key={talent.id}>
                    <CardItems>
                    <CardItem>
                    <Link to={'/radio/' + talent.id} decoration='none' >
                        <ImgWrapper>
                        <Img src={talent.image} alt=''/>
            
                    </ImgWrapper>
                        <Text> <h1> {talent.title} </h1> </Text>
                        </Link>
                    </CardItem>
                    </CardItems>
                </CardWrapper>
                ))
            }
            
            </CardContainer>
            </a>
        </CardS>
        </div>     
        
    )
}

export default NewTalent

import React from 'react';

export default class GoogleAd3 extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
        <div className="Ad">
       <ins className="adsbygoogle"
     style={{display:'block',background:"#030617"}}
      data-ad-client="ca-pub-2968429081843062"
     data-ad-slot="8703718386"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins> 
     </div>
 );
  }
}
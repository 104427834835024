import React from 'react'
import Styled from 'styled-components';
import { useSelector} from "react-redux"
import {selectCharts} from "../../../features/chart/chartSlice"
import {Link} from 'react-router-dom'

const Top100 = () => {
    const charts = useSelector(selectCharts)



    return (
        
        <Container >
            <ContentContainer>
            <ImgWrapper><img src="hot.svg" 
            height="20" alt="Howwe Hot 100" style={{margin: "0 auto;"}}/></ImgWrapper>
            <h1 style={{fontSize:'16px', color: "white", fontweight: "900"}}>SESE Music Chart Powered by Paparazzi Promoz</h1>
               <TableContainer style={{ marginTop: '20px', alignSelf:'center', justifyContent:'center'}}>
                   <table >
                      
                        <tbody>
                                    { charts && 
                                        charts.map((chart) =>(
                                            <Link to={'/ziki/' + chart.id} decoration='none' >
            
                            <Wraped style={{textDecoration:'none'}}>
                                    <tr key={chart.id} style={{ marginBottom: '20px', cursor: 'Pointer',}}>
                                            <th style={{fontSize: '30px',color: '##1e2536', padding: '30px', fontStyle: 'italic', }} scope="row">{chart.rank}</th>
                                            <td class="chartphoto">
                                                <div class="innerchartphoto">
                                                <img src={chart.image} alt={chart.song} style={{width: '60px', height: '60px'}} />
                                                </div>
                                                </td>
                                            <ContentWraperTwo style={{marginLeft: 20, flexDirection: 'column', display: 'flex', }}>
                                            <td style={{fontSize: '23px', fontFamily: 'roboto', color:'#ed317e', textDecoration:'none'}}>{chart.song}</td>
                                            <td style={{fontSize: '12px', color: 'white', margin:'35px' }}>{chart.artist}</td>

                                            </ContentWraperTwo>
                                        </tr>
                            </Wraped>
                            </Link>
                            ))
                        }
                        </tbody>
                   </table>
               </TableContainer>
            </ContentContainer>
        </Container>
  )
                    }
export default Top100;

const Container = Styled.div`
 background-color: #030617;
 /* background: url("/images/bg7.jpg") center center / cover no-repeat fixed ; */
  text-decoration: none!important;
overflow: hidden; 
h1 {
    text-decoration: none!important;
    color: white;
    padding-left: 20px;
}
td {
    text-decoration: none!important; 
}
@media only screen and (max-width: 768px){
        }
`

const TableContainer = Styled.div`
    text-decoration: none!important;
text-align: center;
justify-content: center;
align-items: center;
display: flex;
color: white;
@media only screen and (max-width: 768px){

        }

`
const ContentWraperTwo = Styled.div`

@media only screen and (max-width: 768px){
   
        }

`

const ContentContainer = Styled.div`

h1 {
    text-align: center;

}
@media only screen and (max-width: 768px){
    h1 {
        font-size: 15px;
    }
        }

`
const Wraped = Styled.div`
border-bottom: 0.01px solid grey ;
@media only screen and (max-width: 768px){
    font-size: 5px;

    h1 {
    }
        }

`
const ImgWrapper =Styled.div`
height: 60px;
align-items: center;
text-align: center;
background-color:#030617;
padding: 10px;
border:80px;
img {
    width: 100%;
    height: 100%;

}

@media only screen and (max-width: 768px){
        }



`
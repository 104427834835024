import {React} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import { useSelector} from "react-redux"
import {selectArtists} from "../../features/artist/artistSlice"

function ArtistsPage() {
    const artists = useSelector(selectArtists)
    return (
        
        <CardS>
        <Header text-decoration="none">Trending Artists</Header>

            <CardContainer>
            { artists && 
                            artists.map((artist)=>(

                <CardWrapper key={artist.id}>
                    <CardItems>
                    <CardItem>
                    <Link to={'/artistsDetails/' + artist.id} decoration='none' >
                        <ImgWrapper>
                        <Img src={artist.image} alt=''/>
                        </ImgWrapper>
                        <Text> {artist.name}</Text>
                        </Link>
                    </CardItem>
                    </CardItems>
                </CardWrapper>
                ))
            }
            
            </CardContainer>
        </CardS>
            
        
    )
}

export default ArtistsPage



const CardS =styled.div`
  padding: 0.2rem;
  background: url("/images/bg.jpg") center center / cover no-repeat fixed ;
background-repeat: repeat;
text-decoration: none !important;
margin: 0;

a {
    text-decoration: none !important;
}
`
const CardContainer =styled.div`
 display: grid;
 grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 10px;
@media screen and (max-width:768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr ;
}  
@media only screen and (min-width : 480px) {
   

}

`

const CardWrapper =styled.div`
 position: relative;
`
const CardItems =styled.ul`
text-decoration: none;
list-style: none;
`
const CardItem =styled.div`
    display: flex;
    flex: 1;
    display: flex;
    flex-flow: column;
    width: 100%;
    overflow: hidden;
    text-decoration: none;
`

const ImgWrapper =styled.div`
position: relative;
    width: 100%;
    padding-top: 97%;
    overflow: hidden;
`
const Img =styled.img`
border-radius: 50%;
 position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
`

const Text =styled.h1`
font-size:1rem;
color: #333333;
text-decoration: none !important;
text-align: center;
font-weight: 700!important;
`
const Header =styled.h1`
font-size:1rem;
color: black;
text-decoration: none !important;
text-align: center;
text-decoration: underline!important;
font-weight:900;
`



import React from 'react'
//import Header from './header'
import Content from './Content'
import {Helmet} from "react-helmet";
//import GoogleAd from '../../components/GoogleAd';
//import Ads from '../../components/pages/Ads'

const homepage2 = () => {
    return (
        <>
              <Helmet>
            <title>KW TV</title>
            <meta
      name="description"
      content="Kasese's first English-Urban radio station streaming from hills of Snowy Rwenzori Mountains"
    />
    <meta name="keywords" content="mp3 song of, mp3 song in, mp3 song, Mp3 song, download mp3 song, mp3 song download, mp3 song download free"/>

    <style type="text/css"></style>

        </Helmet>
        <Content />
  
        </>
    )
}

export default homepage2

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    gospels: [],

}

const gospelSlice = createSlice({
    name: "gospel",
    initialState,
    reducers: {
        setGospels: (state, action) => {
            state.Gospels  = action.payload

        }, 
    }
})

export const { setGospels } = gospelSlice.actions;
export const selectGospels = (state) => state.oldie.oldies;
export default gospelSlice.reducer;
import React from 'react';

export default class GoogleAd2 extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
        <div className="Ad">
       <ins className="adsbygoogle"
     style={{display:'block', background:"#030617"}}
     data-ad-format="fluid"
     data-ad-layout-key="-gk+2d+39-5t+2g"
    data-ad-client="ca-pub-2968429081843062"
     data-ad-slot="8975569481"></ins> 
     </div>
 );
  }
}
import {React, useEffect} from 'react'
import { setSongs } from '../../features/song/songSlice'
import {useDispatch} from "react-redux"
import firebase from '../../firebase'
import Nav3 from '../Navs/SideView/Nav3'
import Trending from '../audio/Songs/Trending'
import {Helmet} from 'react-helmet'
import Ads from '../../components/pages/Ads'
import GoogleAd2 from '../GoogleAd2'


function Song() {
    const dispatch = useDispatch();

    useEffect(()=>{
        firebase
      .firestore()
      .collection("songs").onSnapshot((snapshot) => {
            let tempSongs = snapshot.docs.map((doc) => {
                return { id: doc.id, ...doc.data() };
            });
            console.log(tempSongs);
            dispatch(
                setSongs(tempSongs)
            );
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
        <Helmet>
              <title>Trending Songs in Kasese</title>
            <meta
      name="description"
      content="Best of Kasese, fully equipped entertainment online platform  "
    />
    <meta name="keywords" content="mp3 song of, mp3 song in, mp3 song, Mp3 song, download mp3 song, mp3 song download, mp3 song download free"/>

    <link rel="apple-touch-icon" href="/snow fm.png" />
    <style type="text/css"></style>

        </Helmet>
            <Nav3 />
            <Trending />
            {/* <Ads /> */}
  <GoogleAd2/> 
        </>
    )
}

export default Song

import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';

const Modal = ({ isOpen, closeModal, content }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsModalOpen(true);

      setTimeout(() => {
        setIsModalOpen(false);
        closeModal();
      }, 9000);
    }
  }, [isOpen, closeModal]);

  return (
    <Wrapper>
      <div className={`modal-overlay ${isModalOpen ? 'show-modal' : ''}`}>
        <article className="modal-container">
          <img src={'/apple-touch-icon.png'} alt="" />
          <div className="modal-info">
            <h2>AD:</h2>
            <p>Download the Konzo Mobi App from the Google Play Store</p>
            <button className="close-modal-btn" onClick={closeModal}>
              <FaTimes />
            </button>
          </div>
        </article>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: grid;
    place-items: center;
    transition: var(--transition);
    visibility: hidden;
    z-index: -1;
  }

  .show-modal {
    visibility: visible;
    z-index: 10;
  }

  .modal-container {
    transform: scale(0);
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    background: #161C2F;
    border-radius: #202938;
    color: white;
    width: 70vw;
    max-width: 668px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 1.5rem;
    padding: 4rem 1.5rem;

    @media (max-width: 592px) {
      flex-direction: column;
    }

    img {
      width: 15rem;
    }

    a {
      text-decoration: underline;
      color: var(--white);
    }
  }

  .show-modal .modal-container {
    transform: scale(1);
  }

  .close-modal-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.25rem;
    background: transparent;
    border-color: transparent;
    color: var(--red-dark);
    cursor: pointer;
  }
`;

export default Modal;

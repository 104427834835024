import React, { useEffect, useState } from "react";
import "./Converter.css";
import DownloadButton from "./DownloadButton";
//import Sideview from '../../../Navs/SideView/Sideview'
//import Header from '../../../Navs/SideView/Header'
import Nav3 from '../../../Navs/SideView/Nav3'
//import TopNav from '../../../Navs/SideView/TopNav'
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom'
import firebase from '../../../../firebase'
//import LinearWithValueLabel from './Progress'
//import {Link} from 'react-router-dom'
//import Player from '../Player/Player'
//import GoogleAd from '../../../GoogleAd'
import {
  Container,
  Wrapper,
  Row,
  Column1,
  Column2,
  Card,
  CardContainer,
  CardItems,
  CardWrapper,
  ArtistImg,
  ArtistName,
  HeroContainer,
  LoopVideo,
  VideoBg,
  HeroContent,
  Playlist
} from './Convertercss'
 import GoogleAd3 from "../../../GoogleAd3";
// import Fixedad from "../../../FixedAd";
 import CompliexAd from "../../../Compliex";
import Modal from "./Modal";
function NewConverter() {
  const { id } = useParams();
  const [newi, setSong ] = useState([]);
  
  
  
  useEffect(() => {
      firebase
      .firestore()
      .collection('newis')
      .doc(id)
      .get()
      .then ((doc) => {
          if (doc.exists) {
              setSong(doc.data());
          }
          else {
              console.log("no such document in firebase 🔥");
            }
      })
      .catch((error) => {
          console.log("Error getting document:", error);
        });
  }, [id])    
  
  return (

    <>
   <Modal /> 
    
    <Nav3 />
    { newi && (
            <>
      
      <Helmet>
        
      <title>{newi.title}  | MP3 Download | Audio Download</title>
            <meta
      name="description"
      content={newi.title}
    />
   <meta property="og:title" content={newi.title} />
   <meta property="og:image" content={newi.image} />
     
     <link rel="icon" href={`${newi.image}`} />
    
        </Helmet>
        
        <HeroContainer>
        <LoopVideo>
            <VideoBg src={newi.image} alt="" />
        </LoopVideo>
        <HeroContent>

        </HeroContent>
        </HeroContainer>
        <DownloadButton  id2={newi.url} type="mp3" />


        <Container>

            <Wrapper>
                <Row>
            <Column1>
            <Card>
                <CardContainer>
                    <CardWrapper >
                        <CardItems>
            <ArtistImg src={newi.image} alt="" />
            <ArtistName>{newi.title}</ArtistName>
            </CardItems>
            </CardWrapper>
            </CardContainer>
            </Card>
            </Column1>
            <Column2>
                      <CardContainer>
                    <CardWrapper >
                        <CardItems>
            <ArtistName>{newi.title} " Free Mp3 Download "</ArtistName>
            <DownloadButton  id2={newi.url} type="mp3" />

            </CardItems>
            </CardWrapper>
            </CardContainer>
            </Column2>
            </Row>
            </Wrapper>
        </Container>
<GoogleAd3/> 
<CompliexAd />

        </>
        )}
          </>  
    )
}

export default NewConverter;


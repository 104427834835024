/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import styled from 'styled-components'
//import Player from './Player'
//import HerosSlider from './HerosSlider'
const Tv =() => {
   
   
    return (
        <>
             <HeroContainer>
                 
<Img>
                <a href='/'>
                <img src="/kwtv.png" alt="/" />
                     </a>
                     </Img>
        <div className="video">
           <iframe  width="100%" height="400px" src='https://player.viloud.tv/embed/channel/90f3cf218b436eb37994ea23fe0e7d9d?autoplay=1&volume=1&controls=1&title=0&share=0&open_playlist=0&random=0'
 title=""  frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>

  </div>

     
        </HeroContainer>
        </>
    )
    }

export default Tv



 
const HeroContainer = styled.div`

background-image: url("/bg 2.jpg") ;

//background: url('./images/ywd logo.PNG') center center/cover no-repeat;
height: 100%;
width: 100%;
/* margin-left: 50px;
margin-right: 50px; */
text-align:center;
justify-content: center;
align-items:center;
cursor: pointer;
object-fit: contain;
position: relative;
color: #fff;
& :hover {

    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {

}
`;
const Img = styled.div`
position: relative;
img {
    width: 300px;
    height: 200px;
}

`
import firebase from 'firebase';

var firebaseConfig ={
    apiKey: "AIzaSyB5QW9Wack76vdLVeBKlVfLacQismjxCBg",
    authDomain: "konzo-vibes.firebaseapp.com",
    projectId: "konzo-vibes",
    storageBucket: "konzo-vibes.appspot.com",
    messagingSenderId: "542289244063",
    appId: "1:542289244063:web:4c8983b92b48f87e3417aa",
    measurementId: "G-6CFPP72K5C"
  };

  firebase.initializeApp(firebaseConfig)
  firebase.analytics();

  export default firebase;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    recents: [],

}

const recentSlice = createSlice({
    name: "recent",
    initialState,
    reducers: {
        setRecents: (state, action) => {
            state.recents  = action.payload

        }, 
    }
})

export const { setRecents } = recentSlice.actions;
export const selectRecents = (state) => state.recent.recents;
export default recentSlice.reducer;
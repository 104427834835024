import {React, useEffect} from 'react'
import firebase from '../../firebase'
import {useDispatch} from "react-redux"
import {setArtists} from "../../features/artist/artistSlice"
import ArtistsPage from '../audio/ArtistsPage'
import {Helmet} from 'react-helmet'
//import Herosection2 from '../audio/HeroSection2'
import Nav2 from '../Navs/Nav2'
function Artists() {
  const dispatch = useDispatch();

    useEffect(()=>{
      firebase
      .firestore()
      .collection("artists").onSnapshot((snapshot) => {
          let tempArtists = snapshot.docs.map((doc) => {
              return { id: doc.id, ...doc.data() };
          });
          console.log(tempArtists);
          dispatch(
              setArtists(tempArtists)
          );
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


    return (
        <>
        <Helmet>
              <title>Artists</title>
            <meta
      name="description"
      content="Best of Kasese, fully equipped entertainment online platform  "
    />
    <meta name="keywords" content="Discover the vibrant musical talents of Kasese and Bundibugyo artists on Konzo Vibez. Enjoy the best local music and explore an extensive platform dedicated to promoting regional artists. Tune in to Snow FM Kasese, our online radio station advocating for disability rights. Join our community today"/>

    <link rel="apple-touch-icon" href="/snow fm.png" />
    <style type="text/css"></style>

        </Helmet>
           <Nav2 />
         <ArtistsPage />
            
        </>
    )
}

export default Artists

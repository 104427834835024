import React, { useState } from 'react';
import './Trending.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectRecents } from '../../../features/recent/recentSlice';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton'; // Import the Skeleton component
import {
  CardS,
  Header,
  CardContainer,
  CardItem,
  CardItems,
  CardWrapper,
  ImgWrapper,
  Img,
  Text,
} from './Cardscss';

const SearchInputContainer = styled.div`
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 900;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
`;

const NoResultsMessage = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: #fff;
`;

const NoResultsLink = styled(Link)`
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
  margin-left: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const LoadingSkeleton = () => (
  <CardWrapper>
    <CardItems>
      <CardItem>
        <Skeleton height={200} />
        <Text>
          <Skeleton height={20} width={150} />
        </Text>
      </CardItem>
    </CardItems>
  </CardWrapper>
);

function Gospel() {
  const recents = useSelector(selectRecents);
  const [searchTerm, setSearchTerm] = useState('');
  const filteredRecents = recents.filter((recent) => {
    if (!searchTerm) {
      return true;
    } else if (
      recent.title &&
      recent.title.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return true;
    }
    return false;
  });

  return (
    <div className="Wrapper">
      <CardS>
        <SearchInputContainer>
          <SearchInput
            id="searchInput"
            type="text"
            placeholder="Search for a recent song here..."
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
        </SearchInputContainer>
        <Link to="/" text-decoration="none">
          <Header text-decoration="none">
            <h1>Download Recently Added Hits</h1>
          </Header>
          <CardContainer>
            {filteredRecents.length > 0 ? (
              filteredRecents.map((recent) => (
                <CardWrapper key={recent.id}>
                  <CardItems>
                    <CardItem>
                      <Link to={'/musics/' + recent.id} decoration="none">
                        <ImgWrapper>
                          <Img src={recent.image} alt="" />
                        </ImgWrapper>
                        <Text>
                          <h1>{recent.title} </h1>
                        </Text>
                      </Link>
                    </CardItem>
                  </CardItems>
                </CardWrapper>
              ))
            ) : (
              <NoResultsMessage>
                No results found.{' '}
                <NoResultsLink to="/konzohits">Back to main search</NoResultsLink>
              </NoResultsMessage>
            )}
            {/* Loading Skeleton */}
            {[...Array(6)].map((_, index) => (
              <LoadingSkeleton key={index} />
            ))}
          </CardContainer>
        </Link>
      </CardS>
    </div>
  );
}

export default Gospel;

import {React} from 'react'
import './Trending.css'
import {Link} from 'react-router-dom'
import { useSelector} from "react-redux"
import {selectNonstops} from "../../../features/nonstop/nonstopSlice"
import {CardS, Header, CardContainer, CardItem, CardItems, CardWrapper, ImgWrapper, Img, Text} from './Cardscss'

function Nonstops() {
    const nonstops = useSelector(selectNonstops)

    return (
        
        <div className="Wrapper">
        <CardS>
        <a href='/' text-decoration="none">
            <a href='/' text-decoration="none">
        <Header text-decoration="none"> Watch TV Stations in Western Uganda</Header>
        </a>
            <CardContainer>
            { nonstops && 
                            nonstops.map((nonstop)=>(

                <CardWrapper key={nonstop.id}>
                    <CardItems>
                    <CardItem>
                    <Link to={'tv/' + nonstop.id} decoration='none' >
                        <ImgWrapper>
                        <Img src={nonstop.image} alt=''/>
                        </ImgWrapper>
                        <Text> <h1>{nonstop.title}</h1></Text>
                        </Link>
                    </CardItem>
                    </CardItems>
                </CardWrapper>
                ))
            }
            
            </CardContainer>
            </a>
        </CardS>
        </div>     
        
    )
}

export default Nonstops


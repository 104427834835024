import {React, useEffect} from 'react'
import firebase from '../../firebase'
import {useDispatch} from "react-redux"
import {setOldies} from "../../features/oldies/oldieSlice"
import Oldies from '../audio/Songs/Oldies'
//import Herosection2 from '../audio/HeroSection2'
//import Nav2 from '../Navs/Nav2'
import GoogleAd2 from '../../components/GoogleAd2';
import Nav3 from '../Navs/SideView/Nav3'
import Helmet from 'react-helmet'

function NonstopPage() {
  const dispatch = useDispatch();

    useEffect(()=>{
      firebase
      .firestore()
      .collection("oldies").onSnapshot((snapshot) => {
          let tempOldies = snapshot.docs.map((doc) => {
              return { id: doc.id, ...doc.data() };
          });
          console.log(tempOldies);
          dispatch(
              setOldies(tempOldies)
          );
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


    return (
        <>
        <Helmet>
              <title>KASESE Oldies</title>
            <meta
      name="description"
      content="Best of Kasese, fully equipped entertainment online platform  "
    />
    <meta name="keywords" content="mp3 song of, mp3 song in, mp3 song, Mp3 song, download mp3 song, mp3 song download, mp3 song download free"/>

    <link rel="apple-touch-icon" href="/logo512.jpg" />
    <style type="text/css"></style>

        </Helmet>
        <Nav3 />
         <Oldies />
         <GoogleAd2/> 
        </>
    )
}

export default NonstopPage

import React, { useState } from 'react';
import './Trending.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSongs } from '../../../features/song/songSlice';
import styled from 'styled-components'; // Import styled-components
import {
    CardS,
    Header,
    CardContainer,
    CardItem,
    CardItems,
    CardWrapper,
    ImgWrapper,
    Img,
    Text,
  } from './Cardscss';
  
const SearchInputContainer = styled.div`
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
`;

function Trending() {
  const songs = useSelector(selectSongs);
  const [searchTerm, setSearchTerm] = useState('');
  const filteredSongs = songs.filter((song) => {
    if (!searchTerm) {
      return true; // Show all items if searchTerm is empty
    } else if (
      song.title &&
      song.title.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return true; // Show items that match the search term
    }
    return false; // Hide items that don't match the search term
  });

  return (
    <div className="Wrapper">
      <CardS>
        <SearchInputContainer>
          <SearchInput
            id="searchInput"
            type="text"
            placeholder="Search here..."
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
        </SearchInputContainer>
        <a href="/" text-decoration="none">
          <Header text-decoration="none">
            <h1>Download Trending Music in Kasese A-Z</h1>
          </Header>
        </a>
        <a href="/" text-decoration="none">
          <CardContainer>
            {filteredSongs.length > 0 ? (
              filteredSongs.map((song) => (
                <CardWrapper key={song.id}>
                  <CardItems>
                    <CardItem>
                      <Link to={'/music/' + song.id} decoration="none">
                        <ImgWrapper>
                          <Img src={song.image} alt="" />
                        </ImgWrapper>
                        <Text>
                          <h1>{song.title}</h1>
                        </Text>
                      </Link>
                    </CardItem>
                  </CardItems>
                </CardWrapper>
              ))
            ) : (
              <div className="no-results-message">
                No results found. <Link to="/">Back to main search</Link>
              </div>
            )}
          </CardContainer>
        </a>
      </CardS>
    </div>
  );
}

export default Trending;

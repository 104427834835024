import React, {} from 'react';
import styled from 'styled-components';
//import { Link as LinkR } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import Search from './Search';
//import SideList from './SideList';
//import {GoThreeBars} from 'react-icons/go'
    
const Header = ({toggle}) => {

    

        return (

        
             <Nav >
                  <MobileIcon >  
            <FaBars/>
                     </MobileIcon>
                     <NavbuttonX onClick={toggle}>
        <ButtonX >
          
        </ButtonX>
      </NavbuttonX>

                    <NavbarContainer>
                      <a href='/'>
<NavLogo src="/konzomobi.png" alt="/">

                        </NavLogo>
                        </a>
                        <NavMenu>
                            <Search/>
                        
                        </NavMenu>
                    </NavbarContainer>
                </Nav>
    )
}

export default Header

const Nav = styled.nav`
  background: #161C2F;
    height: 60px;
    display: flex;
    font-size: 1rem;
    top: 0;
    width: 100%;
    z-index: 10;
    vertical-align: baseline;
position: sticky;
    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`;

const NavbarContainer = styled.div`
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: center;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    gap: 30px;
`;

const NavLogo = styled.img`
border-radius: 50%;
margin-left: 60px;
width:60px;
height:60px;
@media only screen and (max-width:960px) {
  justify-content:center;
  align-items: center;

}

    @media screen and (max-width: 768px) {
    }
`;


const NavMenu = styled.ul`
    display: flex;
    list-style: none;
    text-align: center;
    width: 100%;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;


const MobileIcon = styled.div`
    display: block;
    position: absolute;
    margin-top: -10px;
    left: 40px;
    color:white;
    background: transparent;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    overflow: hidden;

`


const NavbuttonX = styled.div`
  position: fixed;
  z-index: 10;
  background: transparent;
`;

const ButtonX = styled.button`
  background-color: transparent;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin: 0.5rem 0 0 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
/* 
  &::before,
  &::after {
    content: "";
    background-color: #fff;
    height: 2px;
    width: 1rem;
    position: absolute;
    transition: all 0.8s ease;
  }

  &::before {
    top: ${(props) => (props.clicked ? "1.5" : "1rem")};
    transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }

  &::after {
    top: ${(props) => (props.clicked ? "1.2" : "1.5rem")};
    transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  } */
`;

// const NavItem = styled.li`
//     align-items: center;
//     height: 60px;
//     margin-left: 5px;

// `;

// const NavLinks = styled(LinkR)`
//     color: #fff;
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
//     text-decoration: none;
//     padding: 0 1rem;
//     font-size: 20px;
//     height: 100%;
//     cursor: pointer;

//     &.active {
//         border-bottom: 3px solid rgb(126, 253, 45);
//     }
//     &:hover {
//         transition: all 0.3s ease-in-out;
//         background: ${({primary}) => (primary ? '#888' : '#333')};
//         transform: scale(1.05);
//     }
// `;



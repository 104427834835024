import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import firebase from '../../firebase'
import Nav2 from '../Navs/Nav2'
import {Helmet} from "react-helmet";

function Details(props) {
    const { id } = useParams();
    const [artist, setArtist ] = useState([]);
    
    
    
    useEffect(() => {
        firebase
      .firestore()
      .collection('artists')
        .doc(id)
        .get()
        .then ((doc) => {
            if (doc.exists) {
                setArtist(doc.data());
            }
            else {
                console.log("no such document in firebase 🔥");
              }
        })
        .catch((error) => {
            console.log("Error getting document:", error);
          });
    }, [id])    

    return (
        <>
        <Nav2 />
        { artist && (
            <>
                     <Helmet>
            <title>{artist.name}</title>
            <meta
      name="description"
      content="Discover the vibrant musical talents of Kasese and Bundibugyo artists on Konzo Vibez. Enjoy the best local music and explore an extensive platform dedicated to promoting regional artists. Tune in to Snow FM Kasese, our online radio station advocating for disability rights. Join our community today"
    />
    <link rel="apple-touch-icon" href="/snow fm.png" />
    <style type="text/css"></style>

        </Helmet>
  
        <HeroContainer>
        <LoopVideo>
            <VideoBg src={artist.image} alt="" />
        </LoopVideo>
        <HeroContent>
        <ArtistNameHero>{artist.name}</ArtistNameHero>

        </HeroContent>
        </HeroContainer>


        <Container>

            <Wrapper>
                <Row>
            <Column1>
            <Card>
                <CardContainer>
                    <CardWrapper >
                        <CardItems>
            <ArtistImg src={artist.image} alt="" />
            <ArtistName>{artist.name}</ArtistName>
            </CardItems>
            </CardWrapper>
            </CardContainer>
            </Card>
            </Column1>
            <Column2>
                      <CardContainer>
                    <CardWrapper >
                        <CardItems>
            <ArtistName>{artist.name}'s Playlist</ArtistName>
            <Playlist>COMING SOON, STILL COLLECTING DATA, PLEASE DO BEAR WITH US</Playlist>
            </CardItems>
            </CardWrapper>
            </CardContainer>
            </Column2>
            </Row>
            </Wrapper>
        </Container>
        </>
        )}
          </>  
    )
}


export default Details


const Container =styled.div`
color: #fff;
display: flex;
height: 560px;

@media screen and (max-width:768px) {
    display: flex;
}
`

const Wrapper =styled.div`
display:grid;
z-index: 1;
height:11px;
width:100%;
//max-width: 1100px;
margin-right:auto ;
margin-left:auto;
padding: 0 24px;
justify-content: center;
margin-bottom: 0;
@media screen and  (max-width:768px) {
    height: 100%;
}

`
const Row =styled.div`
display: grid;
height:300px;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: ${({imgStart})=>(imgStart ? `'col2 col1'` : `'col1 col2'`)} ;
@media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart})=>(imgStart ? `'col2' 'col2'` : `'col1 col1' 'col2 col2'`)} ;

}
`

const Column1 =styled.div`
box-sizing: border-box;
display: flex;
flex-wrap: wrap;
height:380px;
margin-bottom: 0px;
padding: 0;
grid-area: col1; 

`

const Column2 =styled.div`
box-sizing: border-box;
border-left: solid;
margin-bottom: 0px;
padding: 0 5px;
grid-area: col2;
@media screen and (max-width:768px){
padding: 0;
    border-top: solid;
border-left:none ;

}

`
const Playlist = styled.h3`
font-size:12px;
color: black;
border: dotted 10px #db5d5d;
`

const ArtistName =styled.h1`
font-size:1rem;
color: black;

`
const ArtistImg =styled.img`
width:155px;
height:155px;
`

const Card =styled.div`
  padding: 4rem;
  background: #fff;

`

const CardContainer =styled.div`
 display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
`

const CardWrapper =styled.div`
position: relative;
  margin: 50px 0 45px;
`

const CardItems =styled.div`

`

//hero_content


const LoopVideo = styled.div`
//object-fit: cover;
width: 100%;
height: 100%;
position: absolute;
//z-index: -1;
top: 0;
bottom: 0;
right: 0;
left: 0;
overflow: hidden;
@media only screen and (max-width:768px){
    width: 100%;
    height: 100%;
}
`
const HeroContainer = styled.div`
background: white;
//background: url('./images/ywd logo.PNG') center center/cover no-repeat;
height: 400px;
width: 100%;
display: flex;
justify-content: center;
flex-direction:column;
align-items:center;
cursor: pointer;
/* margin-left: 60px;
margin-right: 60px;  
justify-content: center;
align-items:center; */
object-fit: contain;
position: relative;

& :hover {

    
}


@media screen and (max-width:768px){
    margin-left: 0;
margin-right: 0;
width:100%;

    
}


`;

const HeroContent = styled.div`
z-index: 3;
max-width:1200px;
position: absolute;
padding: 8px 24px;
display: flex;
flex-direction:column;
align-items:center;

@media screen and (max-width:768px){
max-width: 90%;
}

/* :before {
    position: absolute;
color:yellow;
    font-size: 10px;
   position: center;  */
   
    
`;
const VideoBg = styled.img`
width: 100%;      
height: 100%;
-o-object-fit: cover;
object-fit: cover;
background: #232a34;
//box-shadow: brown;
`
const ArtistNameHero =styled.h1`
font-size:3rem;
color: #db5d5d;

`
import React, { useEffect, useState } from "react";
import "./Converter.css";
import DownloadButton from "./DownloadButton";
//import Sideview from '../../../Navs/SideView/Sideview'
//import Header from '../../../Navs/SideView/Header'
import Nav3 from '../../../Navs/SideView/Nav3'
//import TopNav from '../../../Navs/SideView/TopNav'
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom'
import firebase from '../../../../firebase'
//import LinearWithValueLabel from './Progress'
//import {Link} from 'react-router-dom'
//import Player from '../Player/Player'
import {
  Container,
  Wrapper,
  Row,
  Column1,
  Column2,
  Card,
  CardContainer,
  CardItems,
  CardWrapper,
  ArtistImg,
  ArtistName,
  HeroContainer,
  LoopVideo,
  HeroContent,
  Playlist
} from './Convertercss'
// import GoogleAd from "../../../GoogleAd";
 import GoogleAd3 from "../../../GoogleAd3";
// import Fixedad from "../../../FixedAd";
 import CompliexAd from "../../../Compliex";
import Modal from "./Modal";
function VideoConverter() {
  const { id } = useParams();
  const [video, setTalent ] = useState([]);
  
  
  
  useEffect(() => {
      firebase
      .firestore()
      .collection('videos')
      .doc(id)
      .get()
      .then ((doc) => {
          if (doc.exists) {
              setTalent(doc.data());
          }
          else {
              console.log("no such document in firebase 🔥");
            }
      })
      .catch((error) => {
          console.log("Error getting document:", error);
        });
  }, [id])    
  
  return (

    <>
   <Modal /> 
    
    <Nav3 />
    { video && (
            <>
      
      <Helmet>
      <title>{video.title}</title>
            <meta
      name="description"
      content={video.title}
    />
      <meta property="og:title" content={video.title} />
      <meta property="og:image" content={video.image} />
      

     <link rel="icon" href={`${video.image}`} />
        </Helmet>
        
        <HeroContainer>
        <LoopVideo>
        <Playlist><h1>{video.title}</h1></Playlist>

        <div className="video">
    <iframe
      width="100%"
      height="100%"
      src={`https://www.youtube.com/embed/${video.url}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>        </LoopVideo>
        <HeroContent>
        </HeroContent>
        </HeroContainer>
        <iframe style={{background:"#030617"}} title='vide' class="button-api-frame" src={`https://loader.to/api/button/?url=https://www.youtube.com/watch?v=${video.url}&f=mp4&color=198754#`}
 width="100%" height="120px"  >
 </iframe>
 <iframe style={{background:"#030617"}} title='ve' class="button-api-frame" src={`https://loader.to/api/button/?url=https://www.youtube.com/watch?v=${video.url}&f=mp3&color=198754#`}
 width="100%" height="120px"  >
 </iframe>

<Container>

<Wrapper>
    <Row>
<Column1>
<Card>
    <CardContainer>
        <CardWrapper >
            <CardItems>
       

<ArtistImg src={video.image} alt="" />
<ArtistName>{video.title}</ArtistName>

</CardItems>
</CardWrapper>
</CardContainer>
</Card>
</Column1>
<Column2>
          <CardContainer>
        <CardWrapper >
            <CardItems>
            <ArtistName> <h1>Download {video.title} Mp3</h1></ArtistName>
    <DownloadButton  id2={video.url} type="mp3" />


  <Playlist><h1>free audio music</h1></Playlist>
            
            
</CardItems>
</CardWrapper>
</CardContainer>
</Column2>
</Row>
</Wrapper>
</Container>
<GoogleAd3/> 

<CompliexAd />
      
      
    </>
    
        )}
    </>
    

  );
}

export default VideoConverter;


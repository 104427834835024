import {React, useEffect} from 'react'
import firebase from '../../firebase'
import {useDispatch} from "react-redux"
import NewTalent from '../audio/Songs/NewTalent'
import {setTalents} from "../../features/talent/talentSlice"
//import Herosection2 from '../audio/HeroSection2'
//import Nav2 from '../Navs/Nav2'
import GoogleAd2 from '../../components/GoogleAd2';
import Nav3 from '../Navs/SideView/Nav3'
import Helmet from 'react-helmet'

function NewTalentPage() {
  const dispatch = useDispatch();


  useEffect(()=>{
    firebase
    .firestore()
    .collection("stations")
    .orderBy('title', 'asc')
    .onSnapshot((snapshot) => {
        let tempTalents = snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
        });
        console.log(tempTalents);
        dispatch(
            setTalents(tempTalents)
        );
    })
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])


    return (
        <>
            <Helmet>
              <title>Listen to Radio Stations in Western Uganda </title>
            <meta
      name="description"
    />
    <meta name="keywords" content="mp3 song of, mp3 song in, mp3 song, Mp3 song, download mp3 song, mp3 song download, mp3 song download free"/>

    <link rel="apple-touch-icon" href="/logo512.jpg" />
    <style type="text/css"></style>

        </Helmet>
        <Nav3 />
         <NewTalent />

         <GoogleAd2/> 
        </>
    )
}

export default NewTalentPage

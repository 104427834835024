import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    songs: [],

}

const nonstopSlice = createSlice({
    name: "nonstop",
    initialState,
    reducers: {
        setNonstops: (state, action) => {
            state.nonstops  = action.payload

        }, 
    }
})

export const { setNonstops } = nonstopSlice.actions;
export const selectNonstops = (state) => state.nonstop.nonstops;
export default nonstopSlice.reducer;
import React from 'react';
import styled from 'styled-components';
// import { FaBars } from 'react-icons/fa';
//import { Link as LinkS } from 'react-scroll';
// import { Link as LinkR } from 'react-router-dom';

const Navbar = () => {
    return (
        <>
        <Nav>
            <NavContainer>
                <Logo to='/'>
                    <Img>
                    <a href='/'>
                        <img src="/snow fm.png" alt="/" />
                     </a>
                     </Img>
                </Logo>

                    <LogoName>
                        <a href='/'>
                        Rwenzori's Snowy Mountains Hit Music Station
                     </a>
                    </LogoName>
            </NavContainer>
            </Nav>
                    
        </>
    );
};

export default Navbar;
const Nav = styled.nav`
background: #000000;
height: 60px;
display: flex;
justify-content: flex-start;
font-size: 1rem;
position: sticky;
top: 0;
z-index: 10;
//display: flex;
//background: rgba(0, 0, 0, 0.5);
width: 100%;

@media screen and (max-width: 768px) {
height: 60px;
}
`; 
const NavContainer = styled.div`
display: flex;
justify-content: space-between;
font-family: 'Josefin Sans', sans-serif;
color: black;
@media screen and (max-width: 960px) {
justify-content: center;
align-items: center;
display: grid;

}

`;

const Logo = styled.div`
top:0;
display: grid;
`;

const Img = styled.div`
justify-content:flex-start;
margin-left: 40px;
img {
    width: 50%;
}
@media screen and (max-width: 768px) {
align-items: center;
left: 0;
img {
    width: 25%;
}
}
`;

const LogoName = styled.div`
    font-size: 25px;
    justify-content: flex-end;
    width: 50vw;
    margin-left: 450px;
    a {
        text-decoration: none;
        color: #DB5D5D;
    font-family: "Comfortaa", Sans-serif;

    }
    @media screen and (max-width:768px){
        margin-left: 20px;
        font-size: 80%;
        width:100%
    }
`;

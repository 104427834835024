import styled from "styled-components";

export const Container = styled.div`
  background-color: #030617;
  display: flex;
  height: 1510px;

  @media screen and (max-width: 768px) {
    display: flex;
    height: auto;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  margin-bottom: 0;

  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? "'col2 col1'" : "'col1 col2'"};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? "'col2' 'col2'" : "'col1 col1' 'col2 col2'"};
  }
`;

export const Column1 = styled.div`
  background: #030617 !important;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  margin-bottom: 0px;
  padding: 0;
  grid-area: col1;
`;

export const Column2 = styled.div`
  background: #030617 !important;
  box-sizing: border-box;
  border-left: solid;
  margin-bottom: 0px;
  padding: 0 5px;
  grid-area: col2;

  @media screen and (max-width: 768px) {
    padding: 0;
    border-top: solid;
    border-left: none;
  }
`;

export const Playlist = styled.h3`
  font-size: 0.80rem;
  color: white;
`;

export const ArtistName = styled.h1`
  font-size: 0.81rem;
  color: white;

  h1 {
    font-size: 0.81rem;
    color: white;
  }
`;

export const ArtistImg = styled.img`
  width: 255px;
  height: 255px;
`;

export const Card = styled.div`
  padding: 4rem;
  background-color: #030617;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
`;

export const CardWrapper = styled.div`
  position: relative;
  margin: 50px 0 45px;
`;

export const CardItems = styled.div``;

export const LoopVideo = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
`;

export const HeroContainer = styled.div`
  background-color: #030617;
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  object-fit: contain;
  position: relative;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    max-width: 90%;
  }
`;

export const VideoBg = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const ArtistNameHero = styled.h1`
  font-size: 1rem;
  color: orange;
`;

export const PlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

export const PlayerLogo = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
`;

export const PlayerContent = styled.div`
  flex: 1;
`;

import React, { useEffect, useState } from "react";
import "./Converter.css";
import DownloadButton from "./DownloadButton";
import Nav3 from '../../../Navs/SideView/Nav3';
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import firebase from '../../../../firebase';
import styled from "styled-components";
import GoogleAd3 from "../../../GoogleAd3";
import CompliexAd from "../../../Compliex";
import Modal from "./Modal";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

// Styled Components
const Container = styled.div`
  background-color: #030617;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background: #1c2237;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const CardWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const CardItems = styled.div`
  margin: 20px 0;
`;

const ArtistImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
`;

const ArtistName = styled.h1`
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 10px;
`;

const PlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const PlayerLogo = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
`;

const PlayerContent = styled.div`
  flex: 1;
`;
const AdContainer = styled.div`
  width: 100%;
  max-width: 728px;
  margin: 20px 0;
`;
function OnlineRadioPlayer() {
  const { id } = useParams();
  const [radio, setRadio] = useState(null);

  useEffect(() => {
    firebase
      .firestore()
      .collection('stations')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setRadio(doc.data());
        } else {
          console.log("No such document in Firebase 🔥");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, [id]);

  return (
    <>
      <Modal />
      <Nav3 />
      {radio && (
        <>
          <Helmet>
            <title>{radio.title} Live Stream</title>
            <meta name="description" content={radio.title} />
            <meta property="og:title" content={radio.title} />
            <meta property="og:image" content={radio.image} />
            <link rel="icon" href={`${radio.image}`} />
          </Helmet>
          
          <Container>
            <CardContainer>
              <CardWrapper>
                <CardItems>
                  <ArtistImg src={radio.image} alt={radio.title} />
                  <ArtistName>{radio.title}</ArtistName>
                </CardItems>
                <CardItems>
                  <PlayerWrapper>
                    <PlayerLogo src={radio.image} alt="Logo" />
                    <PlayerContent>
                      <AudioPlayer
                        src={radio.url}
                        autoPlay
                        controls
                        onPlay={e => console.log("onPlay")}
                      />
                    </PlayerContent>
                  </PlayerWrapper>
                </CardItems>
              </CardWrapper>
            </CardContainer>
            <AdContainer>
              <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-2968429081843062"
                data-ad-slot="8703718386"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </AdContainer>

            <AdContainer>
              <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-2968429081843062"
                data-ad-slot="8456694735"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </AdContainer>
          </Container>
        </>
      )}
    </>
  );
}

export default OnlineRadioPlayer;
